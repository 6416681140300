import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../Dashboard/User/Loader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify"; // Moved up
import "react-toastify/dist/ReactToastify.css"; // Moved down
const config = require('../config')

const SignupPage = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('Low');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const isValidEmail = () => {
    return !/\S+@\S+\.\S+/.test(email);
  };

  const validatePasswordStrength = (password) => {
    const strongRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/;
    const mediumRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.{6,})/;

    if (strongRegex.test(password)) return 'Strong';
    if (mediumRegex.test(password)) return 'Medium';
    return 'Low';
  };

  // const validatePassword = () => {
  //   const capitalRegex = /[A-Z]/;
  //   const specialCharRegex = /[!@#$]/;
  //   const numericRegex = /[0-9]/;
  //   return (
  //     capitalRegex.test(password) &&
  //     specialCharRegex.test(password) &&
  //     numericRegex.test(password) &&
  //     password.length >= 6
  //   );
  // };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (isValidEmail()) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setPasswordStrength(validatePasswordStrength(value));
  };

  const getStrengthBarClass = () => {
    switch (passwordStrength) {
      case 'Strong':
        return 'bg-green-500 w-full';
      case 'Medium':
        return 'bg-yellow-500 w-2/3';
      default:
        return 'bg-red-500 w-1/3';
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
    if (value !== password) {
      setConfirmPasswordError('Passwords do not match.');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSignup = async () => {
    setLoading(true);
    try {
      const signupResponse = await fetch(config.backendDomain + '/signupbe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, conpass: confirmPassword }),
      });

      const signupData = await signupResponse.json();
      if (signupResponse.ok) {

        setLoading(false);
        if (signupData.message) {
          toast.success(signupData.message);
          window.location.href = '/signin';
        } else if (signupData.error) {
          toast.success(signupData.error);
        }
      } else {
        toast.success('Failed to sign up');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.success('Error. Please try again.');
    }
  };

  return (
    <div className="container mx-auto p-8 bg-gradient-to-r from-blue-800 to-green-300">
      <ToastContainer className="mt-0" />
      {loading && <Loader />}
      <nav className="fixed top-0 left-0 right-0 bg-white border-b-2 border-gray-200 shadow-lg z-50 py-4 hidden md:block">
        <div className="container mx-auto flex justify-between items-center px-8">
          <Link to="/">
            <img src="logo.png" alt="Logo.png" className="h-16 ml-8 " />
          </Link>
        </div>
      </nav>
      <div className="flex justify-center items-stretch h-screen bg-gradient-to-r from-blue-800 to-green-300">
        <div className="flex-initial bg-indigo-100 shadow-md rounded px-8 pt-2 pb-8 mb-20 mt-32 flex flex-col justify-center">
          <h2 className="text-2xl font-bold mb-6">Signup</h2>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${emailError && 'border-red-500'
                }`}
              id="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              required
            />
            {emailError && <p className="text-red-500 text-xs italic">{emailError}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="password">
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                placeholder="Enter your password"
                value={password}
                onChange={handlePasswordChange}
                className="border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-3 flex items-center text-gray-600 hover:text-indigo-500 focus:outline-none"
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </button>
            </div>
            {/* Password Strength Indicator */}
            <div className="mt-2 h-2 w-full bg-gray-200 rounded">
              <div
                className={`${getStrengthBarClass()} h-full rounded transition-all duration-300`}
              ></div>
            </div>
            <p className="mt-1 text-sm text-gray-600">Password Strength: {passwordStrength}</p>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
              Confirm Password
            </label>
            <div className="relative">
              <input
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${confirmPasswordError && 'border-red-500'
                  }`}
                id="confirmPassword"
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="absolute inset-y-0 right-3 flex items-center text-gray-500 hover:text-indigo-500 focus:outline-none"
              >
                <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
              </button>
            </div>
            {confirmPasswordError && <p className="text-red-500 text-xs italic">{confirmPasswordError}</p>}
          </div>

          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={handleSignup}
            >
              Sign Up
            </button>
          </div>
        </div>
        <div className="flex-initial bg-white shadow-md rounded px-8 pt-6 pb-8 mb-20 ml-4 mt-32 flex flex-col justify-center hidden md:block">
          <h2 className="text-2xl font-bold mb-6">Welcome</h2>
          <p className="text-gray-700">
            Transform your logistics effortlessly with our cutting-edge SaaS platform.{' '}
          </p>
          <p className="text-gray-700">From streamlined operations to enhanced visibility, we've got you covered. </p>
          <p className="text-gray-700">Join us now and elevate your logistics game to new heights. Experience </p>
          <p className="text-gray-700">the future of logistics management today.</p>
          <p className="mt-4 text-sm text-gray-600">
            Have an account? <a href="/signin" className="font-medium text-indigo-600 hover:text-indigo-500">Login here</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
