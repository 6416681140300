import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp, faShoppingCart, faSearchLocation, faBiking, faCreditCard, faTools, faUpload, faCar, faMap, faBox, faLandmark, faStore, faCity, faShippingFast, faBoxOpen, faMapMarker, faShoppingBag, faLock, faUserShield, faBuilding, faCodeBranch, faMapMarkerAlt, faCartPlus, faCashRegister, faClipboardList, faListAlt, faFileInvoice, faDollarSign, faTruck, faBars, faBell, faEnvelope, faMoon, faSun, faExpand, faHandshake, faReceipt, faUsers, faGlobe, faFileAlt, faChartLine, faIndustry, faPodcast } from '@fortawesome/free-solid-svg-icons';
import profilePic from '../Assets/pic.jpeg';
import TeamInvite from './User/TeamInvite';
// import RolePermission from './User/RolePermission';
import Country from './Locations/Country';
import Zone from './Locations/Zone';
import Region from './Locations/Region';
import State from './Locations/State';
import City from './Locations/City';
import PostalCode from './Locations/PostalCode';
import Area from './Locations/Area';
import CountryData from './Locations/CountryData';
import Sales from './Dash/Sales';
import Purchase from './Dash/Purchase';
import UserRole from './User/UserRole';
import RolePermission from './User/RolePermission2';
import LogoUpload from '../Authentication/LogoUpload';
import UserProfile from '../Authentication/UserProfile';
import CreateBranch from './Masters/CreateBranch';
import BranchType from './Masters/BranchType';
import Customer from './Masters/Customer';
import Vendor from './Masters/Vendor';
import DeliveryBoy from './Masters/Deliveryboy';
import Tarrif from './Masters/Tarrif';
import Booking from './Transaction/Booking';
import Manifest from './Transaction/Manifest2';
import Inscan from './Transaction/Inscan';
import Drs from './Transaction/Drs';
import Delivery from './Transaction/Delivery';
import StationeryPurchase from './Stationery/StationeryPurchase';
import StationerySales from './Stationery/StationerySales';
import StationerySalesClient from './Stationery/StationerySalesClient';
import Product from './Stationery/Product';
import Paymentmode from './Tools/Paymentmode';
import Transportmode from './Tools/Transportmode';
import PodDocument from './Tools/PodDocument';
import BookingReport from './Reports/Bookingreport';
import ManifestReport from './Reports/Dispatchreport';
import Performance from './Reports/Performance';
import CreateInvoice from './Invoice/CreateInvoice';
import Invoice from './Invoice/Invoice';


function Dashboard() {
  const [theme, setTheme] = useState('dark'); // Set default theme to 'dark'
  const [showSales, setShowSales] = useState(true);
  const [showPurchase, setShowPurchase] = useState(false);
  const [selectedService, setSelectedService] = useState('');
  const [showSidebar, setShowSidebar] = useState(true);
  const [sidebarWidth, setSidebarWidth] = useState('w-64');
  const [showTrackingbar, setShowTrackingbar] = useState(true);
  const [showDashboardMenu, setShowDashboardMenu] = useState(true);
  const [showMasterMenu, setShowMasterMenu] = useState(false);
  const [showToolsMenu, setShowToolsMenu] = useState(false);
  const [showBranch, setShowBranch] = useState(false);
  const [showBranchType, setShowBranchType] = useState(false);
  const [showTarrif, setShowTarrif] = useState(false);
  const [showTarrifTemplates, setShowTarrifTemplates] = useState(false);
  const [showCustomer, setShowCustomer] = useState(false);
  const [showVendor, setShowVendor] = useState(false);
  const [showDeliveryBoy, setShowDeliveryBoy] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showUserRole, setShowUserRole] = useState(false);
  const [showPermission, setShowPermission] = useState(false);
  const [showTeamPage, setShowTeamPage] = useState(false);
  const [ShowLocation, setShowLocation] = useState(false);
  const [showCountry, setShowCountry] = useState(false);
  const [showZone, setShowZone] = useState(false);
  const [showRegion, setShowRegion] = useState(false);
  const [showState, setShowState] = useState(false);
  const [showCity, setShowCity] = useState(false);
  const [showPostalCode, setShowPostalCode] = useState(false);
  const [showArea, setShowArea] = useState(false);
  const [showCountryData, setShowCountryData] = useState(false);
  const [showStationeryMenu, setShowStationeryMenu] = useState(false);
  const [showStationeryPurchase, setShowStationeryPurchase] = useState(false);
  const [showStationerySales, setShowStationerySales] = useState(false);
  const [showStationerySalesClient, setShowStationerySalesClient] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [showTransactionMenu, setShowTransactionMenu] = useState(false);
  const [showBooking, setShowBooking] = useState(false);
  const [showManifest, setShowManifest] = useState(false);
  const [showInscan, setShowInscan] = useState(false);
  const [showDrs, setShowDrs] = useState(false);
  const [showDelivery, setShowDelivery] = useState(false);
  const [showInvoiceMenu, setShowInvoiceMenu] = useState(false);
  const [showCreateInvoice, setShowCreateInvoice] = useState(false);
  const [showReportsMenu, setShowReportsMenu] = useState(false);
  const [showBookingReport, setShowBookingReport] = useState(false);
  const [showManifestReport, setShowManifestReport] = useState(false);
  const [showPerformance, setShowPerformance] = useState(false);
  const [ShowLogoUpload, setShowLogoUpload] = useState(false);
  const [showPaymentmode, setShowPaymentmode] = useState(false);
  const [showTransportmode, setShowTransportmode] = useState(false);
  const [showPodDocument, setShowPodDocument] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);


  const navigate = useNavigate();

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    document.documentElement.classList.toggle('dark', newTheme === 'dark');
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
    setSidebarWidth(showSidebar ? 'w-32' : 'w-64'); // toggle sidebar width
  };


  const toggleTrackingbar = () => {
    setShowTrackingbar(!showTrackingbar);
  };

  const toggleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  // Function to handle service change
  const handleServiceChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedService(selectedValue);
    navigate(`/${selectedValue.toLowerCase().replace(/\s+/g, '-')}`);
  };

  // Function to handle sidebar menu change

  const handleDashboardClick = () => {
    setShowDashboardMenu(!showDashboardMenu);
    setShowMasterMenu(false);
    setShowUserMenu(false);
    setShowLocation(false);
    setShowStationeryMenu(false);
    setShowTransactionMenu(false);
    setShowInvoiceMenu(false);
    setShowToolsMenu(false);
    setShowReportsMenu(false);
    setShowInvoice(false)

  };

  const handleMasterClick = () => {
    setShowMasterMenu(!showMasterMenu);
    setShowDashboardMenu(false);
    setShowUserMenu(false);
    setShowLocation(false);
    setShowStationeryMenu(false);
    setShowTransactionMenu(false);
    setShowInvoiceMenu(false);
    setShowReportsMenu(false);
    setShowInvoice(false)
    setShowToolsMenu(false);


  };

  const handleUserClick = () => {
    setShowUserMenu(!showUserMenu);
    setShowDashboardMenu(false);
    setShowMasterMenu(false);
    setShowLocation(false);
    setShowStationeryMenu(false);
    setShowToolsMenu(false);
    setShowTransactionMenu(false);
    setShowInvoiceMenu(false);
    setShowReportsMenu(false);
    setShowInvoice(false)
    setShowInvoice(false)
  };

  const handleLocationClick = () => {
    setShowLocation(!ShowLocation);
    setShowDashboardMenu(false);
    setShowMasterMenu(false);
    setShowUserMenu(false);
    setShowToolsMenu(false);
    setShowStationeryMenu(false);
    setShowTransactionMenu(false);
    setShowInvoiceMenu(false);
    setShowReportsMenu(false);
    setShowInvoice(false)

  };
  const handleStationeryClick = () => {
    setShowStationeryMenu(!showStationeryMenu);
    setShowDashboardMenu(false);
    setShowMasterMenu(false);
    setShowUserMenu(false);
    setShowLocation(false);
    setShowTransactionMenu(false);
    setShowInvoiceMenu(false);
    setShowToolsMenu(false);
    setShowReportsMenu(false);
    setShowInvoice(false)
  };
  const handleTransactionClick = () => {
    setShowTransactionMenu(!showTransactionMenu);
    setShowDashboardMenu(false);
    setShowMasterMenu(false);
    setShowUserMenu(false);
    setShowLocation(false);
    setShowStationeryMenu(false);
    setShowInvoiceMenu(false);
    setShowReportsMenu(false);
    setShowInvoice(false)
    setShowToolsMenu(false);
  };
  const handleInvoicingClick = () => {
    setShowInvoiceMenu(!showInvoiceMenu);
    setShowDashboardMenu(false);
    setShowMasterMenu(false);
    setShowUserMenu(false);
    setShowLocation(false);
    setShowStationeryMenu(false);
    setShowTransactionMenu(false);
    setShowReportsMenu(false);
    setShowInvoice(false)
    setShowToolsMenu(false);
  };
  const handleReportsClick = () => {
    setShowReportsMenu(!showReportsMenu);
    setShowDashboardMenu(false);
    setShowMasterMenu(false);
    setShowUserMenu(false);
    setShowLocation(false);
    setShowStationeryMenu(false);
    setShowTransactionMenu(false);
    setShowInvoiceMenu(false);
    setShowToolsMenu(false);
  };
  const handleToolsClick = () => {
    setShowToolsMenu(!showToolsMenu);
    setShowReportsMenu(false);
    setShowInvoice(false)
    setShowDashboardMenu(false);
    setShowMasterMenu(false);
    setShowUserMenu(false);
    setShowLocation(false);
    setShowStationeryMenu(false);
    setShowTransactionMenu(false);
    setShowInvoiceMenu(false);

  };

  // SubMenus

  const handleSalesClick = () => {
    setShowSales(true);
    setShowPurchase(false);
    setShowBranch(false);
    setShowVendor(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowStationerySalesClient(false);
    setShowTarrif(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowProducts(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowPodDocument(false);
    setShowInvoice(false);
    setShowLogoUpload(false);
  };

  const handlePurchaseClick = () => {
    setShowPurchase(true);
    setShowSales(false);
    setShowBranch(false);
    setShowStationerySalesClient(false);
    setShowCustomer(false);
    setShowVendor(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowTarrif(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowProducts(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowPodDocument(false);
    setShowLogoUpload(false);
    setShowInvoice(false)
  };

  const handleBranchClick = () => {
    setShowBranch(true);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowVendor(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowProducts(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationerySalesClient(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowPermission(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowPodDocument(false);
    setShowLogoUpload(false);
    setShowInvoice(false)
  };

  const handleBranchTypeClick = () => {
    setShowBranchType(true);
    setShowBranch(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowStationerySalesClient(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowVendor(false);
    setShowBookingReport(false);
    setShowProducts(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowPodDocument(false);
    setShowLogoUpload(false);
    setShowInvoice(false)
  };
  const handleTarrifClick = () => {
    setShowTarrif(true);
    setShowBranchType(false);
    setShowBranch(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowProducts(false);
    setShowStationerySalesClient(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowVendor(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowPermission(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowPodDocument(false);
    setShowLogoUpload(false);
    setShowInvoice(false)
  };
  const handleTarrifTemplatesClick = () => {
    setShowTarrifTemplates(true);
    setShowTarrif(false);
    setShowBranchType(false);
    setShowBranch(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowVendor(false);
    setShowCreateInvoice(false);
    setShowStationerySalesClient(false);
    setShowBookingReport(false);
    setShowProducts(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowPermission(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowPodDocument(false);
    setShowLogoUpload(false);
    setShowInvoice(false)
  };


  const handleCustomerClick = () => {
    setShowCustomer(true);
    setShowVendor(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationerySalesClient(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowProducts(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowPodDocument(false);
    setShowLogoUpload(false);
    setShowInvoice(false)
  };

  const handleVendorClick = () => {
    setShowVendor(true);
    setShowCustomer(false);
    setShowSales(false);
    setShowStationerySalesClient(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowProducts(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowPodDocument(false);
    setShowLogoUpload(false);
    setShowInvoice(false)
  };

  const handleDeliveryBoyClick = () => {
    setShowDeliveryBoy(true);
    setShowVendor(false);
    setShowCustomer(false);
    setShowSales(false);
    setShowStationerySalesClient(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowProducts(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };

  const handleUserRoleClick = () => {
    setShowUserRole(true);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowStationerySalesClient(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowVendor(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowProducts(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };

  const handleCreateTeamClick = () => {
    setShowTeamPage(true);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowVendor(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowStationerySalesClient(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowProducts(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };

  const handlePermissionClick = () => {
    setShowPermission(true);
    setShowTeamPage(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowCountry(false);
    setShowStationerySalesClient(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowVendor(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowProducts(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };

  const handleCountryClick = () => {
    setShowCountry(true);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowStationerySalesClient(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowVendor(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowProducts(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };

  const handleRegionClick = () => {
    setShowRegion(true);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowVendor(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowStationerySalesClient(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowProducts(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };

  const handleZoneClick = () => {
    setShowZone(true);
    setShowCity(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowState(false);
    setShowRegion(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowVendor(false);
    setShowBookingReport(false);
    setShowProducts(false);
    setShowManifestReport(false);
    setShowBranchType(false);
    setShowStationerySalesClient(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };

  const handleStateClick = () => {
    setShowState(true);
    setShowCity(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowZone(false);
    setShowRegion(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowProducts(false);
    setShowVendor(false);
    setShowStationerySalesClient(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };

  const handleCityClick = () => {
    setShowCity(true);
    setShowArea(false);
    setShowPostalCode(false);
    setShowState(false);
    setShowZone(false);
    setShowRegion(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowStationerySalesClient(false);
    setShowCreateInvoice(false);
    setShowProducts(false);
    setShowVendor(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };
  const handlePostalCodeClick = () => {
    setShowPostalCode(true);
    setShowArea(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowRegion(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowVendor(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowProducts(false);
    setShowBranchType(false);
    setShowStationerySalesClient(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };
  const handleAreaClick = () => {
    setShowArea(true);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowRegion(false);
    setShowVendor(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowProducts(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowStationerySalesClient(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };
  const handleCountryDataClick = () => {
    setShowCountryData(true);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowVendor(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowStationerySalesClient(false);
    setShowProducts(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };

  const handleStationeryPurchaseClick = () => {
    setShowStationeryPurchase(true);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowVendor(false);
    setShowStationerySalesClient(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowProducts(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };

  const handleStationerySalesClick = () => {
    setShowStationerySales(true);
    setShowStationerySalesClient(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowVendor(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowProducts(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };

  const handleStationerySalesClientClick = () => {
    setShowStationerySalesClient(true);
    setShowStationerySales(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowVendor(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowProducts(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };

  const handleProductsClick = () => {
    setShowProducts(true);
    setShowStationerySales(false);
    setShowStationerySalesClient(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowBooking(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowVendor(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };

  const handleBookingClick = () => {
    setShowBooking(true);
    setShowSales(false);
    setShowPurchase(false);
    setShowStationerySalesClient(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowProducts(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowManifest(false);
    setShowCreateInvoice(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowVendor(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };

  const handleManifestClick = () => {
    setShowManifest(true);
    setShowSales(false);
    setShowPurchase(false);
    setShowStationerySalesClient(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowProducts(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowCreateInvoice(false);
    setShowVendor(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };

  const handleInscanClick = () => {
    setShowInscan(true);
    setShowDrs(false);
    setShowDelivery(false);
    setShowManifest(false);
    setShowStationerySalesClient(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowProducts(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowCreateInvoice(false);
    setShowVendor(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };
  const handleDrsClick = () => {
    setShowDrs(true);
    setShowInscan(false);
    setShowDelivery(false);
    setShowStationerySalesClient(false);
    setShowManifest(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowProducts(false);
    setShowVendor(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowCreateInvoice(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };
  const handleDeliveryClick = () => {
    setShowDelivery(true);
    setShowDrs(false);
    setShowStationerySalesClient(false);
    setShowInscan(false);
    setShowManifest(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowVendor(false);
    setShowProducts(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowCreateInvoice(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };
  const handleCreateInvoiceClick = () => {
    setShowCreateInvoice(true);
    setShowInvoice(false)
    setShowManifest(false);
    setShowSales(false);
    setShowStationerySalesClient(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowVendor(false);
    setShowProducts(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };
  const handleInvoiceClick = () => {
    setShowInvoice(true)
    setShowCreateInvoice(false);
    setShowManifest(false);
    setShowSales(false);
    setShowStationerySalesClient(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowVendor(false);
    setShowProducts(false);
    setShowBookingReport(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  }

  const handleBookingReportClick = () => {
    setShowBookingReport(true);
    setShowInvoice(false);
    setShowManifest(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowStationerySalesClient(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowProducts(false);
    setShowCreateInvoice(false);
    setShowVendor(false);
    setShowManifestReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);

  };

  const handleManifestReportClick = () => {
    setShowManifestReport(true);
    setShowManifest(false);
    setShowInvoice(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowStationerySalesClient(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowProducts(false);
    setShowVendor(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowCreateInvoice(false);
    setShowBookingReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);
    setShowPodDocument(false);
  };
  const handlePerformanceClick = () => {
    setShowPerformance(true);
    setShowInvoice(false);
    setShowManifestReport(false);
    setShowManifest(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowCountryData(false);
    setShowStationerySalesClient(false);
    setShowRegion(false);
    setShowStationeryPurchase(false);
    setShowProducts(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowCreateInvoice(false);
    setShowVendor(false);
    setShowBookingReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowTrackingbar(false);
    setShowPaymentmode(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowPodDocument(false);
    setShowLogoUpload(false);
    setShowLogoUpload(false);
  };
  const handleLogoUploadClick = () => {
    setShowLogoUpload(true);
    setShowPaymentmode(false);
    setShowManifestReport(false);
    setShowInvoice(false);
    setShowManifest(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowStationerySalesClient(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowProducts(false);
    setShowVendor(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowCreateInvoice(false);
    setShowBookingReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowPodDocument(false);
  };
  const handlePaymentModeClick = () => {
    setShowPaymentmode(true);
    setShowManifestReport(false);
    setShowInvoice(false);
    setShowManifest(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowStationerySalesClient(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowProducts(false);
    setShowVendor(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowCreateInvoice(false);
    setShowBookingReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowTransportmode(false);
    setShowDeliveryBoy(false);
    setShowPodDocument(false);
    setShowLogoUpload(false);
  };
  const handleTransportModeClick = () => {
    setShowTransportmode(true);
    setShowPaymentmode(false);
    setShowInvoice(false);
    setShowManifestReport(false);
    setShowManifest(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowStationerySalesClient(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowProducts(false);
    setShowVendor(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowCreateInvoice(false);
    setShowBookingReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowDeliveryBoy(false);
    setShowPodDocument(false);
    setShowPodDocument(false);
    setShowLogoUpload(false);
  };

  const handlePodDocumentClick = () => {
    setShowPodDocument(true);
    setShowInvoice(false);
    setShowTransportmode(false);
    setShowPaymentmode(false);
    setShowManifestReport(false);
    setShowManifest(false);
    setShowSales(false);
    setShowPurchase(false);
    setShowBranch(false);
    setShowCustomer(false);
    setShowUserRole(false);
    setShowTeamPage(false);
    setShowCountry(false);
    setShowStationerySalesClient(false);
    setShowCountryData(false);
    setShowRegion(false);
    setShowProducts(false);
    setShowVendor(false);
    setShowStationeryPurchase(false);
    setShowStationerySales(false);
    setShowBooking(false);
    setShowCreateInvoice(false);
    setShowBookingReport(false);
    setShowArea(false);
    setShowPostalCode(false);
    setShowCity(false);
    setShowState(false);
    setShowZone(false);
    setShowBranchType(false);
    setShowPermission(false);
    setShowTarrif(false);
    setShowTarrifTemplates(false);
    setShowInscan(false);
    setShowDrs(false);
    setShowDelivery(false);
    setShowPerformance(false);
    setShowTrackingbar(false);
    setShowDeliveryBoy(false);
    setShowLogoUpload(false);

  };

  return (
    <div className={`flex h-screen ${theme === 'dark' ? 'dark' : ''}`}>
      {/* Sidebar */}
      {showSidebar && (
        <div className={`fixed top-0 left-0 ${sidebarWidth} z-20 bg-white-100 dark:bg-gray-900 text-gray-500 w-64 flex flex-col justify-between border-b-2 border-gray-500 animate__animated animate__fadeIn ${showSidebar ? 'h-screen' : 'auto'}`}>
          <div className="p-2 ">
            <div className="flex items-center mb-12">
            <img
              src="https://via.placeholder.com/64"
              alt="Profile"
              className="w-16 h-16 rounded-full mr-2"
            />
              <div className="p-4 border-b border-gray-700">
                <p className="font-semibold">Company Name</p>
              </div>
            </div>

            {/* Dashboard */}
            <ul>
              <li className="mb-2">
                <button onClick={handleDashboardClick} className={`flex items-center w-56 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showDashboardMenu ? 'bg-blue-600 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                  <FontAwesomeIcon icon={faChartLine} className="mr-2" />
                  <span>Dashboard</span>
                </button>
              </li>

              {showDashboardMenu && (
                <ul className="ml-8 mt-2">
                  <li className="mb-2">
                    <button onClick={handleSalesClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showSales ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700'}`}>
                      <FontAwesomeIcon icon={faShoppingCart} className="mr-2" />
                      <span>Sales</span>
                    </button>
                  </li>
                  <li className="mb-2">
                    <button onClick={handlePurchaseClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showPurchase ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faShoppingBag} className="mr-2" />
                      <span>Purchase</span>
                    </button>
                  </li>
                </ul>
              )}
            </ul>

            {/* locations */}
            <ul>
              <li className="mb-2">
                <button onClick={handleLocationClick} className={`flex items-center w-56 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${ShowLocation ? 'bg-blue-600 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                  <FontAwesomeIcon icon={faGlobe} className="mr-2" />
                  <span>Locations</span>
                </button>
              </li>

              {ShowLocation && (
                <ul className="ml-8 mt-2">
                  <li className="mb-2">
                    <button onClick={handleCountryClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showCountry ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700'}`}>
                      <FontAwesomeIcon icon={faGlobe} className="mr-2" />
                      <span>Country</span>
                    </button>
                  </li>


                  <li className="mb-2">
                    <button onClick={handleZoneClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showZone ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                      <span>Zone</span>
                    </button>
                  </li>

                  <li className="mb-2">
                    <button onClick={handleRegionClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showRegion ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faMap} className="mr-2" />
                      <span>Region</span>
                    </button>
                  </li>

                  <li className="mb-2">
                    <button onClick={handleStateClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showState ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                      <span>State</span>
                    </button>
                  </li>

                  <li className="mb-2">
                    <button onClick={handleCityClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showCity ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faCity} className="mr-2" />
                      <span>City</span>
                    </button>
                  </li>

                  <li className="mb-2">
                    <button onClick={handlePostalCodeClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showPostalCode ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faMapMarker} className="mr-2" />
                      <span>Postal Code</span>
                    </button>
                  </li>

                  {/* <li className="mb-2">
                    <button onClick={handleAreaClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showArea ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faLandmark} className="mr-2" />
                      <span>Area</span>
                    </button>
                  </li> */}
                  <li className="mb-2">
                    <button onClick={handleCountryDataClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showCountryData ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
                      <span>Serviceable Area</span>
                    </button>
                  </li>
                </ul>
              )}
            </ul>

            {/* Office Masters */}
            <ul>
              <li className="mb-2">
                <button onClick={handleMasterClick} className={`flex items-center w-56 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showMasterMenu ? 'bg-blue-600 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                  <FontAwesomeIcon icon={faBuilding} className="mr-2" />
                  <span>Office Masters</span>
                </button>
              </li>

              {showMasterMenu && (
                <ul className="ml-8 mt-2">
                  <li className="mb-2">
                    <button onClick={handleBranchTypeClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showBranchType ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700'}`}>
                      <FontAwesomeIcon icon={faBuilding} className="mr-2" />
                      <span>Branch Type</span>
                    </button>
                  </li>
                  <li className="mb-2">
                    <button onClick={handleBranchClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showBranch ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700'}`}>
                      <FontAwesomeIcon icon={faCodeBranch} className="mr-2" />
                      <span>Branches</span>
                    </button>
                  </li>
                  <li className="mb-2">
                    <button onClick={handleCustomerClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showCustomer ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faUsers} className="mr-2" />
                      <span>Customers</span>
                    </button>
                  </li>
                  <li className="mb-2">
                    <button onClick={handleVendorClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showVendor ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faStore} className="mr-2" />
                      <span>Vendors</span>
                    </button>
                  </li>
                  <li className="mb-2">
                    <button onClick={handleDeliveryBoyClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showDeliveryBoy ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faBiking} className="mr-2" />
                      <span>Delivery Boy</span>
                    </button>
                  </li>

                  {/* <li className="mb-2">
                <button onClick={handleTarrifTemplatesClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showTarrifTemplates ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
                  <span>Tarrif Templates</span>
                </button>
              </li> */}
                </ul>
              )}
            </ul>

            {/* users */}
            <ul>
              <li className="mb-2">
                <button onClick={handleUserClick} className={`flex items-center w-56 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showUserMenu ? 'bg-blue-600 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                  <FontAwesomeIcon icon={faUsers} className="mr-2" />
                  <span>Users</span>
                </button>
              </li>

              {showUserMenu && (
                <ul className="ml-8 mt-2">

                  <li className="mb-2">
                    <button onClick={handleCreateTeamClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showTeamPage ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faUsers} className="mr-2" />
                      <span>Create Team</span>
                    </button>
                  </li>

                  <li className="mb-2">
                    <button onClick={handleUserRoleClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showUserRole ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faUserShield} className="mr-2" />
                      <span>Create Roles</span>
                    </button>
                  </li>

                  <li className="mb-2">
                    <button onClick={handlePermissionClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showPermission ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faLock} className="mr-2" />
                      <span>Role Permissions</span>
                    </button>
                  </li>
                </ul>
              )}
            </ul>

            {/* Stationery */}
            <ul>
              <li className="mb-2">
                <button onClick={handleStationeryClick} className={`flex items-center w-56 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showStationeryMenu ? 'bg-blue-600 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                  <FontAwesomeIcon icon={faIndustry} className="mr-2" />
                  <span>Product</span>
                </button>
              </li>

              {showStationeryMenu && (
                <ul className="ml-8 mt-2">
                  <li className="mb-2">
                    <button onClick={handleProductsClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showProducts ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700'}`}>
                      <FontAwesomeIcon icon={faBox} className="mr-2" />
                      <span>Create Product</span>
                    </button>
                  </li>
                  <li className="mb-2">
                    <button onClick={handleStationeryPurchaseClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showStationeryPurchase ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700'}`}>
                      <FontAwesomeIcon icon={faCartPlus} className="mr-2" />
                      <span>Stationery Purchase</span>
                    </button>
                  </li>
                  <li className="mb-2">
                    <button onClick={handleStationerySalesClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showStationerySales ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faUsers} className="mr-2" />
                      <span>Stock Issue Branch</span>
                    </button>
                  </li>
                  {/* <li className="mb-2">
                <button onClick={handleStationerySalesClientClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showStationerySalesClient ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                <FontAwesomeIcon icon={faCashRegister} className="mr-2" />
                  <span>Stock Issue Client</span>
                </button>
              </li> */}
                </ul>
              )}
            </ul>

            {/* Transaction */}
            <ul>
              <li className="mb-2">
                <button onClick={handleTransactionClick} className={`flex items-center w-56 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showTransactionMenu ? 'bg-blue-600 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                  <FontAwesomeIcon icon={faHandshake} className="mr-2" />
                  <span>Transaction</span>
                </button>
              </li>

              {showTransactionMenu && (
                <ul className="ml-8 mt-2">
                  <li className="mb-2">
                    <button onClick={handleBookingClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showBooking ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700'}`}>
                      <FontAwesomeIcon icon={faClipboardList} className="mr-2" />
                      <span>Booking</span>
                    </button>
                  </li>
                  <li className="mb-2">
                    <button onClick={handleManifestClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showManifest ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faListAlt} className="mr-2" />
                      <span>Manifest</span>
                    </button>
                  </li>
                  <li className="mb-2">
                    <button onClick={handleInscanClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showInscan ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faBoxOpen} className="mr-2" />
                      <span>Shipment Inscan</span>
                    </button>
                  </li>
                  <li className="mb-2">
                    <button onClick={handleDrsClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showDrs ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faShippingFast} className="mr-2" />
                      <span>Out For Delivery</span>
                    </button>
                  </li>
                  <li className="mb-2">
                    <button onClick={handleDeliveryClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showDelivery ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faTruck} className="mr-2" />
                      <span>Delivery</span>
                    </button>
                  </li>
                </ul>
              )}
            </ul>

            {/* Invoicing */}
            <ul>
              <li className="mb-2">
                <button onClick={handleInvoicingClick} className={`flex items-center w-56 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showInvoiceMenu ? 'bg-blue-600 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                  <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
                  <span>Invoicing</span>
                </button>
              </li>

              {showInvoiceMenu && (
                <ul className="ml-8 mt-2">
                  <li className="mb-2">
                    <button onClick={handleTarrifClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showTarrif ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faDollarSign} className="mr-2" />
                      <span>Tarrifs</span>
                    </button>
                  </li>
                  <li className="mb-2">
                    <button onClick={handleCreateInvoiceClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showCreateInvoice ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700'}`}>
                      <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
                      <span>Create Invoice</span>
                    </button>
                  </li>
                  <li className="mb-2">
                    <button onClick={handleInvoiceClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showInvoice ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700'}`}>
                      <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
                      <span>Invoice</span>
                    </button>
                  </li>

                </ul>
              )}
            </ul>

            {/* Reports */}
            <ul>
              <li className="mb-2">
                <button onClick={handleReportsClick} className={`flex items-center w-56 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showReportsMenu ? 'bg-blue-600 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                  <FontAwesomeIcon icon={faReceipt} className="mr-2" />
                  <span>Reports Analysis</span>
                </button>
              </li>

              {showReportsMenu && (
                <ul className="ml-8 mt-2">
                  <li className="mb-2">
                    <button onClick={handleBookingReportClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showBookingReport ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700'}`}>
                      <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
                      <span>Booking Report</span>
                    </button>
                  </li>
                  <li className="mb-2">
                    <button onClick={handleManifestReportClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showManifestReport ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faTruck} className="mr-2" />
                      <span>Dispatch Report</span>
                    </button>
                  </li>
                  <li className="mb-2">
                    <button onClick={handlePerformanceClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showPerformance ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faChartLine} className="mr-2" />
                      <span>Performance Report</span>
                    </button>
                  </li>
                </ul>
              )}
            </ul>            

            {/* Tools */}
            <ul>
              <li className="mb-2">
                <button onClick={handleToolsClick} className={`flex items-center w-56 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showToolsMenu ? 'bg-blue-600 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                  <FontAwesomeIcon icon={faTools} className="mr-2" />
                  <span>Company Tools</span>
                </button>
              </li>

              {showToolsMenu && (
                <ul className="ml-8 mt-2">
                  <li className="mb-2">
                    <button onClick={handleLogoUploadClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${ShowLogoUpload ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700'}`}>
                      <FontAwesomeIcon icon={faUpload} className="mr-2" />
                      <span>Upload Logo</span>
                    </button>
                  </li>
                  <li className="mb-2">
                    <button onClick={handlePaymentModeClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showPaymentmode ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700'}`}>
                      <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
                      <span>Payment Modes</span>
                    </button>
                  </li>
                  <li className="mb-2">
                    <button onClick={handleTransportModeClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showTransportmode ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faCar} className="mr-2" />
                      <span>Transport Modes</span>
                    </button>
                  </li>
                  <li className="mb-2">
                    <button onClick={handlePodDocumentClick} className={`flex items-center w-48 text-gray-800 dark:text-gray-200 font-semibold py-2 px-2 rounded focus:outline-none mt-0 transition duration-300 ease-in-out transform hover:scale-105 ${showPodDocument ? 'bg-orange-400 text-white hover:bg-blue-800' : 'bg-gray-200 dark:bg-gray-800 hover:bg-blue-600'}`}>
                      <FontAwesomeIcon icon={faPodcast} className="mr-2" />
                      <span>Proof Documents</span>
                    </button>
                  </li>
                </ul>
              )}
            </ul>

          </div>
          <div className="p-4 border-t border-gray-700">
            <p className="text-sm text-gray-400">&copy; 2024 NavDoot Pvt Ltd</p>
          </div>
        </div>
      )}
      

      {/* Main Content */}
      <div className={`flex flex-col flex-1 ml-${showSidebar ? '32' : '0'} mt-16 animate__animated animate__fadeIn`}>
        {/* Navbar */}
        <nav className={`bg-blue-500 dark:bg-blue-800 p-4 flex-1 justify-between items-center fixed top-0 left-0 right-0 ml-${showSidebar ? '64' : '32'} z-20`}>
          <div className="flex items-center ">
            <button onClick={toggleSidebar} className="text-gray-300 hover:text-white flex items-center ">
              <FontAwesomeIcon icon={faBars} />
            </button>

            <FontAwesomeIcon icon={faBell} className="text-gray-300 ml-4 cursor-pointer" />
            <FontAwesomeIcon icon={faEnvelope} className="text-gray-300 ml-4 cursor-pointer" />
            <FontAwesomeIcon icon={faSearchLocation} className="text-gray-300 ml-4 cursor-pointer" />
            <div className="flex items-center">
              {/* Full Screen Button */}
              <button
                onClick={toggleFullScreen}
                className="text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 mr-4 focus:outline-none"
              >
                <FontAwesomeIcon icon={faExpand} className="text-gray-300 ml-4 cursor-pointer" />
              </button>
              <button
                onClick={toggleTheme}
                className="text-white-100 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 mr-64 focus:outline-none"
              >
                {theme === 'light' ? <FontAwesomeIcon icon={faMoon} className="text-gray-300 ml-2 cursor-pointer" /> : <FontAwesomeIcon icon={faSun} />}
              </button>
              {/* Select Dropdown for Services */}
              <select
                value={selectedService}
                onChange={handleServiceChange}
                className="text-white ml-32 pl-2 bg-blue-600 dark:bg-blue-900 border-none rounded-md cursor-pointer px-16 py-1 focus:outline-none"
              >
                <option value="">Select Service</option>
                <option value="CRM">CRM</option>
                <option value="ShipmentManagement">Shipment Management</option>
                <option value="ERPAccounting">ERP Accounting</option>
                <option value="WarehouseManagement">Warehouse Management</option>

              </select>

              <button className="bg-orange-400 ml-2 h-8 hover:bg-blue-600 text-white font-semibold py-1 px-4 rounded focus:outline-none">
                <FontAwesomeIcon icon={faArrowCircleUp} className="mr-2" />
                <Link to="/SubscriptionPage">UPGRADE NOW </Link>
              </button>
              <div className="absolute top-0 right-0 mt-0 mr-8">
                <UserProfile />

              </div>
            </div>
          </div>
        </nav>

        {/* Content Area */}
        <div className="p-4 flex-1 ml-32 mt-2">
          {showTeamPage && <TeamInvite />}
          {showPermission && <RolePermission />}
          {showCountryData && <CountryData />}
          {showUserRole && <UserRole />}
          {showBranch && <CreateBranch />}
          {showBranchType && <BranchType />}
          {showCustomer && <Customer />}
          {showVendor && <Vendor />}
          {showDeliveryBoy && <DeliveryBoy />}
          {showTarrif && <Tarrif />}
          {showStationeryPurchase && <StationeryPurchase />}
          {showStationerySales && <StationerySales />}
          {/* {showStationerySalesClient && <StationerySalesClient/>} */}
          {showProducts && <Product />}
          {showBooking && <Booking />}
          {showManifest && <Manifest />}
          {showInscan && <Inscan />}
          {showDrs && <Drs />}
          {showDelivery && <Delivery />}
          {showCountry && <Country />}
          {showZone && <Zone />}
          {showRegion && <Region />}
          {showState && <State />}
          {showCity && <City />}
          {showPostalCode && <PostalCode />}
          {showArea && <Area />}
          {showSales && <Sales />}
          {showPurchase && <Purchase />}
          {showTransportmode && <Transportmode />}
          {ShowLogoUpload && <LogoUpload />}
          {showPaymentmode && <Paymentmode />}
          {showPodDocument && <PodDocument />}
          {showBookingReport && <BookingReport />}
          {showManifestReport && <ManifestReport />}
          {showPerformance && <Performance />}
          {showCreateInvoice && <CreateInvoice />}
          {showInvoice && <Invoice />}

        </div>
      </div>
    </div>
  );
}

export default Dashboard;
