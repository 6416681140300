import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import ReactSelect from 'react-select'; // Import ReactSelect
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify'; // Moved up
import 'react-toastify/dist/ReactToastify.css'; // Moved down

const Area = () => {
  const [areaName, setAreaName] = useState('');
  const [postalcodeId, setpostalcodeId] = useState('');
  const [postalcodes, setpostalcodes] = useState([]);
  const [cityId, setcityId] = useState('');
  const [cityName, setCityName] = useState('');
  const [countries, setcountries] = useState([]);
  const [filteredpostalcodes, setFilteredpostalcodes] = useState([]);
  const [Areas, setAreas] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editItemName, setEditItemName] = useState('');
  const itemsPerPage = 5;
  const [key, setKey] = useState(0);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const config = require('../../config')
  useEffect(() => {
    fetchpostalcodes(); // Fetch initial data when component mounts
    fetchAreas();
  }, []);

  const fetchpostalcodes = async () => {
    try {
      const response = await fetch(config.backendDomain + '/postalcode', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setpostalcodes(data);
      setFilteredpostalcodes(data);

      // Fetch cities based on postalcodes
      const cityResponse = await fetch(config.backendDomain + '/city', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const cityData = await cityResponse.json();
      setcountries(cityData);
    } catch (error) {
      console.error('Error fetching postalcodes and countries:', error);
    }
  };

  const handlepostalcodeSearch = (searchTerm) => {
    const filtered = postalcodes.filter((postalcode) =>
      postalcode.postalcode_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    setFilteredpostalcodes(filtered);
  };

  const fetchAreas = async () => {
    try {
      const response = await fetch(config.backendDomain + '/Area', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      // Sort the data by created_date in descending order
      const sortedData = data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
      setAreas(sortedData);
    } catch (error) {
      console.error('Error fetching Areas:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate that cityName is not empty
    if (!cityName) {
      showAlert('city name is required');
      return;
    }

    console.log('Request Body:', JSON.stringify({ areaName, postalcodeId, cityId, cityName }));

    try {
      const response = await fetch(config.backendDomain + '/Area', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ areaName, postalcodeId, cityId, cityName }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        setAreaName('');
        setpostalcodeId('');
        setcityId('');
        setCityName('');
        fetchAreas();
        // setKey((prevKey) => prevKey + 1);
        // Fetch latest data after successful submission
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error creating Area:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleEdit = async (id, newName) => {
    try {
      const response = await fetch(config.backendDomain + `/Area/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ areaName: newName }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchAreas(); // Fetch latest data after successful edit
        handleCloseEditPopup();
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error updating Area:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(config.backendDomain + `/Area/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchAreas(); // Fetch latest data after successful deletion
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error deleting Area:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleErrorResponse = (status, message) => {
    if (status === 403) {
      showAlert('You are not authorized.');
    } else if (status === 400) {
      showAlert(message || 'Area with the same name already exists');
    } else {
      showAlert('An error occurred.');
    }
  };

  const showAlert = (msg) => {
    toast.info(msg);
  };

  const handleOpenEditPopup = (id, newName) => {
    setEditItemId(id);
    setEditItemName(newName);
    setShowEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setEditItemId(null);
    setEditItemName('');
    setShowEditPopup(false);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Areas.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div key={key} className="relative bg-gray-100 animate__animated animate__fadeIn">
      <ToastContainer className="mt-14" />
      <div className="container mx-auto py-8 mt-0">
        <div className="relative bg-blue-50">
          <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1">Area Gateway</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3 relative">
              <label
                className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
              >
                Enter Area Name
              </label>
              <input
                type="text"
                id="areaName"
                pattern="[a-zA-Z0-9 ]+"
                title="Please enter only letters, numbers, and spaces"
                className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter Area Name"
                value={areaName}
                onChange={(e) => setAreaName(e.target.value)}
                required
              />
            </div>

            <ReactSelect
              id="postalcodeId"
              className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              options={filteredpostalcodes.map((postalcode) => ({
                value: postalcode.postalcode_id,
                label: postalcode.postalcode_name,
              }))}
              value={filteredpostalcodes.find((postalcode) => postalcode.postalcode_name === postalcodeId)}
              onChange={(selectedOption) => {
                const selectedpostalcode = postalcodes.find((postalcode) => postalcode.postalcode_id === selectedOption.value);
                if (selectedpostalcode) {
                  setpostalcodeId(selectedOption.value);
                  setcityId(selectedpostalcode.city_id);
                  setCityName(selectedpostalcode.city_name);
                }
              }}
              placeholder="Select Postal Code"
            />
            <div className="mb-3 relative">
              <label
                className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
              >
                Select Postal Code
              </label>
            </div>
            {postalcodeId && ( // Render city select only if postalcode is selected
              <div className="mb-3 relative">
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Select city
                </label>
                <input
                  type="text"
                  id="cityName"
                  className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Select city"
                  value={cityName}
                  readOnly // Make the input read-only
                />
              </div>
            )}
            <button
              className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Create Area
            </button>
          </form>
          <table className="min-w-full divide-y divide-gray-200 mt-6">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Area </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">postalcode </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">city </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">State </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Region </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Zone </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Country </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Modified Date</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentItems.map((area) => (
                <tr key={area.area_id}>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{area.area_id}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{area.area_name}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{area.postalcode_name}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{area.city_name}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{area.state_name}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{area.region_name}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{area.zone_name}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{area.country_name}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{area.created_date}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{area.modified_date}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">
                    <button
                      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mr-2"
                      onClick={() => handleOpenEditPopup(area.area_id, area.area_name)}
                    >
                      Edit
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => handleDelete(area.area_id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-start items-center mt-4">
            <div>
              {currentPage > 1 && (
                <button
                  className="mr-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                </button>
              )}
            </div>
            <div className="flex space-x-2">
              {[1, 2, 3, 4].map((page) => (
                <button
                  key={page}
                  className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline ${currentPage === page ? 'bg-orange-400' : ''
                    }`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}
            </div>
            <div>
              {currentPage < Math.ceil(Areas.length / itemsPerPage) && (
                <button
                  className="ml-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <ChevronRightIcon className="h-5 w-5" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Edit Popup */}
      {showEditPopup && (
        <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center ">
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center">
            <div className="relative mt-8 bg-gray-100 w-[800px] p-4 left-24 rounded-lg shadow-lg border border-dark">
              <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1">Edit Area</h2>
              <div className="mb-3 relative">
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Edit Area Name
                </label>
                <input
                  type="text"
                  value={editItemName}
                  onChange={(e) => setEditItemName(e.target.value)}
                  className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Edit Area Name"
                />
              </div>
              <div className="mb-3 relative">

                <ReactSelect
                  id="postalcodeId"
                  className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  options={filteredpostalcodes.map((postalcode) => ({
                    value: postalcode.postalcode_id,
                    label: postalcode.postalcode_name,
                  }))}
                  value={filteredpostalcodes.find((postalcode) => postalcode.postalcode_name === postalcodeId)}
                  onChange={(selectedOption) => {
                    const selectedpostalcode = postalcodes.find((postalcode) => postalcode.postalcode_id === selectedOption.value);
                    if (selectedpostalcode) {
                      setpostalcodeId(selectedOption.value);
                      setcityId(selectedpostalcode.city_id);
                      setCityName(selectedpostalcode.city_name);
                    }
                  }}
                  placeholder="Select Postal Code"
                />
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Select Postal Code
                </label>
              </div>
              {postalcodeId && ( // Render city select only if postalcode is selected
                <div className="mb-3 relative">
                  <label
                    className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                  >
                    Select city
                  </label>
                  <input
                    type="text"
                    id="cityName"
                    className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Select city"
                    value={cityName}
                    readOnly // Make the input read-only
                  />
                </div>
              )}

              <div className="mt-4 flex justify-end">
                <button
                  onClick={() => handleEdit(editItemId, editItemName)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                >
                  Save Changes
                </button>
                <button
                  onClick={handleCloseEditPopup}
                  className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Area;
