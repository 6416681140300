import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import ReactSelect from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StationerySales = () => {
  const [billName, setBillName] = useState('');
  const [Bills, setBills] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedParentBranch, setSelectedParentBranch] = useState({});
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [Qty, setQty] = useState('');
  const [Rate, setRate] = useState('');
  const [startFrom, setStartFrom] = useState('');
  const [selectedProducts, setSelectedProducts] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const itemsPerPage = 5;
  const [key, setKey] = useState(0);
  const token = localStorage.getItem('token');
  const config = require('../../config')
  const [owner, setOwner] = useState()
  const [customers, setCustomers] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState({})

  //for edit
  const [editSelectedParentBranch, setEditSelectedParentBranch] = useState()
  const [editBillName, setEditBillName] = useState()
  const [editSelectedCustomer, setEditSelectedCustomer] = useState()
  const [editSelectedProducts, setEditSelectedProducts] = useState()
  const [editStartFrom, setEditStartFrom] = useState()
  const [editQty, setEditQty] = useState()
  const [editRate, setEditRate] = useState()
  const [editStartDate, setEditStartDate] = useState()
  const [currId, setCurrId] = useState()

  useEffect(() => {
    fetchBranches();
    fetchProducts();
    fetchBills();
    fetchCustomers()
  }, []);


  const fetchBranches = async () => {
    try {
      const response = await fetch(config.backendDomain + '/parentbranch', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setBranches(data);
    } catch (error) {
      console.error('Error fetching branches:', error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await fetch(config.backendDomain + '/productsearch', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setFilteredProducts(data);
    } catch (error) {
      console.error('Error fetching Vendors:', error);
    }
  };

  const fetchBills = async () => {
    try {
      const response = await fetch(config.backendDomain + '/stationerysales', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      const sortedData = data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
      setBills(sortedData);
    } catch (error) {
      console.error('Error fetching Stationery:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const endStockValue = Number(startFrom) + Number(Qty);
    const netAmount = Number(Rate) * Number(Qty);

    try {
      const response = await fetch(config.backendDomain + '/stationerysales', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ billName, parentBranchId: selectedParentBranch.value, parentBranch: selectedParentBranch.label, productId: selectedProducts.value, ProductName: selectedProducts.label, Qty, Rate, startFrom, EndStock: endStockValue, startDate, Amount: netAmount, owner, customer_id: selectedCustomer.value, customer_name: selectedCustomer.label }),
      });
      const data = await response.json();

      if (response.ok) {/*  */
        toast.success(data.message);
        setBillName('');
        setSelectedParentBranch('');
        setQty('');
        setRate('');
        setStartFrom('');
        setStartDate('');
        fetchBills();
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error in purchase:', error);
      handleErrorResponse(500, 'An error occurred.');
    }
  };

  const handleEdit = async (Obj) => {
    try {
      const response = await fetch(config.backendDomain + `/stationerysales/${Obj.id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(Obj),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchBills();
        handleCloseEditPopup();
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error updating Sales:', error);
      handleErrorResponse(500, 'An error occurred.');
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(config.backendDomain + `/stationerysales/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchBills();
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error deleting Sales:', error);
      handleErrorResponse(500, 'An error occurred.');
    }
  };

  const handleErrorResponse = (status, message) => {
    if (status === 403) {
      showAlert('You are not authorized.');
    } else if (status === 400) {
      showAlert(message || 'Sales with the same entry already exists');
    } else if (status === 404) {
      showAlert(message || 'Resource not found.');
    } else if (status === 500) {
      showAlert(message || 'Internal server error occurred.');
    } else {
      showAlert('An error occurred.');
    }
  };

  const showAlert = (msg) => {
    toast.info(msg);
  };

  const handleOpenEditPopup = (id) => {
    setCurrId(id.bill_id)
    setEditSelectedParentBranch({ label: id.parent_branch, value: id.parentbranch_id })
    setEditBillName(id.bill_name)
    setEditSelectedCustomer({ label: id.customer_name, value: id.customer_id })
    setEditSelectedProducts({
      value: id.product_id,
      label: id.product_name,
    })
    setEditStartFrom(id.start_from);
    setEditQty(id.qty)
    setEditRate(id.rate)
    setEditStartDate(id.purchase_date)
    setShowEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setShowEditPopup(false);
    setEditSelectedParentBranch(null)
    setEditBillName(null)
    setEditSelectedCustomer(null)
    setEditSelectedProducts(null)
    setEditStartFrom(null);
    setEditQty(null)
    setEditRate(null)
    setEditStartDate(null)
    setCurrId(null)

  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Bills.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const fetchCustomers = async () => {
    try {
      const response = await fetch(config.backendDomain + '/customersearch', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setCustomers(data);

    } catch (error) {
      console.error('Error fetching Customers:', error);
    }
  };

  return (
    <div key={key} className="relative bg-gray-100 animate__animated animate__fadeIn">
      <ToastContainer className="mt-14" />
      <div className="relative bg-blue-50">
        <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1 ">Stock Issue Branch/Client</h2>
        <form onSubmit={handleSubmit} className="grid grid-cols-4 gap-4">
          <div className="mb-3 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Enter Voucher Number
            </label>
            <input
              type="text"
              id="bill"
              title="Please enter a valid bill number without special character"
              className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter Voucher Number"
              value={billName}
              onChange={(e) => setBillName(e.target.value)}
              required
            />
          </div>
          <div className="mb-3 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Branch/Client
            </label>
            <select
              id="owner"
              className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={owner}
              onChange={(e) => setOwner(e.target.value)}
              required >
              <option value="branch">Branch</option>
              <option value="client">Client</option>
            </select>
          </div>
          {owner == 'client' && <div className="mb-3 relative">
            <ReactSelect
              id="parentBranchId"
              className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              options={customers.map((cust) => ({
                value: cust.customer_id,
                label: cust.customer_name,
              }))}
              value={selectedCustomer}
              onChange={setSelectedCustomer}
              placeholder="Select Client"
            />
          </div>}
          <div className="mb-3 relative">
            <ReactSelect
              id="parentBranchId"
              className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              options={branches.map((branch) => ({
                value: branch.branch_id,
                label: branch.branch_name,
              }))}
              value={selectedParentBranch}
              onChange={setSelectedParentBranch}
              placeholder="Select Branch"
            />
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Select Branch/Client
            </label>
          </div>
          <div className="mb-3 relative">
            <ReactSelect
              id="productId"
              className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              options={filteredProducts.map((product) => ({
                value: product.product_id,
                label: product.product_name,
                startFrom: product.number_input
              }))}
              value={selectedProducts}
              onChange={(e) => { setSelectedProducts(e); setStartFrom(e.startFrom) }}
              placeholder="Select Product"
            />
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Select Product
            </label>
          </div>
          <div className="mb-3 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Enter Quantity
            </label>
            <input
              type="number"
              id="Qty"
              pattern="[0-9]{1,8}"
              title="Please enter a number with 1 to 8 digits"
              className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter Quantity"
              value={Qty}
              onChange={(e) => setQty(e.target.value)}
              required
            />
          </div>
          <div className="mb-3 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Rate per WayBill
            </label>
            <input
              type="number"
              id="Rate"
              pattern="^(?:\d*\.\d{1,2}|\d+)$"
              title="Please enter a valid amount (e.g., 100, 100.00, 100.5)"
              className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Rate"
              value={Rate}
              onChange={(e) => setRate(e.target.value)}
              required
            />
          </div>
          <div className="mb-3 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Stock Starting Number
            </label>
            <input
              type="number"
              id="StartFrom"
              pattern="[0-9]{1,20}"
              title="Please enter a number with 1 to 20 digits"
              className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Stock Starting Number"
              value={startFrom}
              onChange={(e) => {
                const fixedPart = selectedProducts?.startFrom || "";
                const inputValue = e.target.value;
                if (!inputValue.startsWith(fixedPart)) {
                  return;
                }
                setStartFrom(inputValue);
              }}
              required
            />
          </div>
          <div className="mb-3 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Stock End Number
            </label>
            <input
              type="text"
              id="EndStock"
              className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Stock End Number"
              value={Number(Qty) > 0 && Number(startFrom) ? Number(startFrom) + Number(Qty) - 1 : null}
              readOnly
              required
            />
          </div>
          <div className="mb-3 relative">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="MM/dd/yyyy"
              placeholderText="Select Issue Date"
              className=" shadow-lg w-[300px] py-2 px-3 pl-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Select Issue Date
            </label>
          </div>
          <div className="mb-3 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Invoice Amount
            </label>
            <input
              type="text"
              id="Amount"
              className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Invoice Amount"
              value={Number(Rate) * Number(Qty)}
              readOnly
              required
            />
          </div>

          <div className="flex-center">
            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">Submit</button>
          </div>
        </form>
        <table className="min-w-full divide-y divide-gray-200 mt-6">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Invoice No.</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Branch</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Client</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Qty</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Start From</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">To end </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Issue Date </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentItems.map((stationerysales) => (
              <tr key={stationerysales.stationerysales_id}>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerysales.bill_name}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerysales.parent_branch}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerysales.customer_name}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerysales.product_name}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerysales.qty}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerysales.start_from}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerysales.end_to}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerysales.amount}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerysales.issue_date}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerysales.created_date}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">
                  <button
                    className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mr-2"
                    onClick={() => handleOpenEditPopup(stationerysales.bill_id, stationerysales.bill_name)}>
                    Edit
                  </button>
                  <button
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                    onClick={() => handleDelete(stationerysales.bill_id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-start items-center mt-4">
          <div>
            {currentPage > 1 && (
              <button
                className="mr-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <ChevronLeftIcon className="h-5 w-5" />
              </button>
            )}
          </div>
          <div className="flex space-x-2">
            {[1, 2, 3, 4].map((page) => (
              <button
                key={page}
                className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline ${currentPage === page ? 'bg-orange-400' : ''
                  }`}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            ))}
          </div>
          <div>
            {currentPage < Math.ceil(Bills.length / itemsPerPage) && (
              <button
                className="ml-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                onClick={() => handlePageChange(currentPage + 1)}>
                <ChevronRightIcon className="h-5 w-5" />
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Edit Popup */}
      {showEditPopup && (
        <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center ">
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center">
            <div className="relative mt-16 bg-gray-100 w-[800px] p-4 left-24 rounded-lg shadow-lg border border-dark">
              <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1">Edit Stationery Sales</h2>

              <div className="mb-3 relative">
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Edit Voucher Number
                </label>
                <input
                  type="text"
                  value={editBillName}
                  onChange={(e) => setEditBillName(e.target.value)}
                  className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Edit Voucher Number"
                />
              </div>

              <div className="mb-3 relative">
                <select
                  id="owner"
                  className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={owner}
                  onChange={(e) => setOwner(e.target.value)}
                  required >
                  <option value="branch">Branch</option>
                  <option value="client">Client</option>
                </select>
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Branch/Client
                </label>
              </div>
              {owner == 'client' && <div className="mb-2">
                <ReactSelect
                  id="parentBranchId"
                  className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  options={customers.map((cust) => ({
                    value: cust.customer_id,
                    label: cust.customer_name,
                  }))}
                  value={editSelectedCustomer}
                  onChange={setEditSelectedCustomer}
                  placeholder="Select Client"
                />
              </div>}
              <div className="mb-3 relative">

                <ReactSelect
                  id="parentBranchId"
                  className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  options={branches.map((branch) => ({
                    value: branch.branch_id,
                    label: branch.branch_name,
                  }))}
                  value={editSelectedParentBranch}
                  onChange={setEditSelectedParentBranch}
                  placeholder="Select Branch/Customer"
                />
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Select Branch/Customer
                </label>
              </div>
              <div className="mb-3 relative">
                <ReactSelect
                  id="productId"
                  className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  options={filteredProducts.map((product) => ({
                    value: product.product_id,
                    label: product.product_name,
                    startFrom: product.number_input
                  }))}
                  value={editSelectedProducts}
                  onChange={(e) => { setEditSelectedProducts(e); setEditStartFrom(e.startFrom) }}
                  placeholder="Select Product"
                />
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Select Product
                </label>
              </div>
              <div className="mb-3 relative">
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Enter Quantity
                </label>
                <input
                  type="number"
                  id="Qty"
                  pattern="[0-9]{1,8}"
                  title="Please enter a number with 1 to 8 digits"
                  className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter Quantity"
                  value={editQty}
                  onChange={(e) => setEditQty(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3 relative">
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Rate
                </label>
                <input
                  type="text"
                  id="Rate"
                  pattern="^(?:\d*\.\d{1,2}|\d+)$"
                  title="Please enter a valid amount (e.g., 100, 100.00, 100.5)"
                  className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Rate"
                  value={editRate}
                  onChange={(e) => setEditRate(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3 relative">
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Stock Starting Number
                </label>
                <input
                  type="number"
                  id="StartFrom"
                  pattern="[0-9]{1,20}"
                  title="Please enter a number with 1 to 20 digits"
                  className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Stock Starting Number"
                  value={editStartFrom}
                  onChange={(e) => {
                    const fixedPart = selectedProducts?.startFrom || "";
                    const inputValue = e.target.value;
                    if (!inputValue.startsWith(fixedPart)) {
                      return;
                    }
                    setEditStartFrom(inputValue);
                  }}
                  required
                />
              </div>
              <div className="mb-3 relative">
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Stock End Number
                </label>
                <input
                  type="text"
                  id="EndStock"
                  className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Stock End Number"
                  value={Number(editQty) > 0 && Number(editStartFrom) ? Number(startFrom) + Number(editQty) - 1 : null}
                  readOnly // Make the input read-only
                  required
                />
              </div>
              <div className="mb-3 relative">
                <DatePicker
                  selected={editStartDate}
                  onChange={(date) => setEditStartDate(date)}
                  dateFormat="MM/dd/yyyy"
                  maxDate={new Date()}
                  placeholderText="Select Issue Date"
                  className=" shadow-lg w-[765px] py-2 px-3 pl-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
                <label

                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Select Issue Date
                </label>
              </div>

              <div className="mb-3 relative">
                <label

                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Invoice Amount
                </label>
                <input
                  type="text"
                  id="Amount"
                  className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Invoice Amount"
                  value={Number(Rate) * Number(Qty)}
                  readOnly // Make the input read-only
                  required
                />
              </div>
              <div className="mt-4 flex justify-end">
                <button
                  onClick={() => handleEdit({
                    parentBranchId: editSelectedParentBranch.value,
                    parentBranch: editSelectedParentBranch.label
                    , billName: editBillName
                    , vendorId: editSelectedCustomer.value,
                    VendorName: editSelectedCustomer.label
                    , productId: editSelectedProducts.value
                    , ProductName: editSelectedProducts.label
                    , startFrom: editStartFrom
                    , Qty: editQty
                    , Rate: editRate,
                    EndStock: Number(editStartFrom) + Number(editQty) - 1,
                    Amount: Number(editRate) * Number(editQty)
                    , startDate: editStartDate, id: currId
                  })}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                >
                  Save Changes
                </button>
                <button
                  onClick={handleCloseEditPopup}
                  className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StationerySales;
