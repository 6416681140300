import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify'; // Moved up
import 'react-toastify/dist/ReactToastify.css'; // Moved down


const Country = () => {
  const [countryName, setCountryName] = useState('');
  const [countries, setCountries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditPopup, setShowEditPopup] = useState(false); // State for controlling the edit popup
  const [editItemId, setEditItemId] = useState(null); // State for storing the ID of the item being edited
  const [editItemName, setEditItemName] = useState(''); // State for storing the new name for editing
  const itemsPerPage = 5;
  const token = localStorage.getItem('token');
  const config = require('../../config')
  useEffect(() => {
    fetchData(); // Fetch initial data when component mounts
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(config.backendDomain + '/country', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await response.json();
      // Sort the data by created_date in descending order
      data = data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
      setCountries(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(config.backendDomain + '/country', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ countryName }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message); // Use toast instead of showAlert
        setCountryName('');
        fetchData(); // Fetch latest data after successful submission
      } else {
        handleErrorResponse(response.status);
      }
    } catch (error) {
      console.error('Error creating country:', error);
      handleErrorResponse(500); // Internal server error
    }
  };


  const handleEdit = async (id, newName) => {
    try {
      const response = await fetch(config.backendDomain + `/country/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ countryName: newName }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchData(); // Fetch latest data after successful edit
        handleCloseEditPopup();
      } else {
        handleErrorResponse(response.status);
      }
    } catch (error) {
      console.error('Error updating country:', error);
      handleErrorResponse(500); // Internal server error
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(config.backendDomain + `/country/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchData(); // Fetch latest data after successful deletion
      } else if (response.status === 400) {
        showAlert(data.message); // Show associated data error message
      } else {
        handleErrorResponse(response.status);
      }
    } catch (error) {
      console.error('Error deleting country:', error);
      handleErrorResponse(500); // Internal server error
    }
  };

  const handleErrorResponse = (status, message) => {
    if (status === 403) {
      showAlert('You are not authorized.');
    } else if (status === 400) {
      showAlert(message || 'Country with the same name already exists');
    } else {
      showAlert('An error occurred.');
    }
  };

  const showAlert = (msg) => {
    toast.info(msg);
  };


  const handleOpenEditPopup = (id, newName) => {
    setEditItemId(id);
    setEditItemName(newName);
    setShowEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setEditItemId(null);
    setEditItemName('');
    setShowEditPopup(false);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = countries.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="relative bg-gray-100 animate__animated animate__fadeIn">
      <ToastContainer className="mt-14" />
      <div className="container mx-auto py-8 mt-0">
        <div className="relative bg-blue-50">
          <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1">Country Gateway</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3 relative">
              <label
                className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
              >
                Enter Country Name
              </label>
              <input
                type="text"
                id="countryName"
                pattern="[a-zA-Z0-9 ]+"
                title="Please enter only letters, numbers, and spaces"
                className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter Country name"
                value={countryName}
                onChange={(e) => setCountryName(e.target.value)}
                required
              />
            </div>
            <button
              className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Create Country
            </button>
          </form>
          <table className="min-w-full divide-y divide-gray-200 mt-6">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Country ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Country</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Modified Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentItems.map((country) => (
                <tr key={country.country_id}>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{country.country_id}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{country.country_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{country.created_date}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{country.modified_date}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">
                    <button
                      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mr-2"
                      onClick={() => handleOpenEditPopup(country.country_id, country.country_name)}
                    >
                      Edit
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => handleDelete(country.country_id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-start items-center mt-4">
            <div>
              {currentPage > 1 && (
                <button
                  className="mr-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                </button>
              )}
            </div>
            <div className="flex space-x-2">
              {[1, 2, 3, 4].map((page) => (
                <button
                  key={page}
                  className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline ${currentPage === page ? 'bg-orange-400' : ''
                    }`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}
            </div>
            <div>
              {currentPage < Math.ceil(countries.length / itemsPerPage) && (
                <button
                  className="ml-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <ChevronRightIcon className="h-5 w-5" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Edit Popup */}
      {showEditPopup && (
        <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
          <div className="relative bg-white w-96 rounded-lg p-8">
            <h2 className="text-lg font-bold mb-4">Edit Country Name</h2>
            <div className="mb-3 relative">
              <label
                className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
              >
                Edit Country Name
              </label>
              <input
                type="text"
                value={editItemName}
                onChange={(e) => setEditItemName(e.target.value)}
                className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Edit New Country Name"
              />
            </div>
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => handleEdit(editItemId, editItemName)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
              >
                Save Changes
              </button>
              <button
                onClick={handleCloseEditPopup}
                className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Country;
