import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import ReactSelect from 'react-select'; // Import ReactSelect
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify'; // Moved up
import 'react-toastify/dist/ReactToastify.css'; // Moved down

const Vendor = () => {
  const [VendorName, setVendorName] = useState('');
  const [Vendors, setVendors] = useState([]);
  const [branches, setBranches] = useState([]);
  const [filteredBranches, setFilteredBranches] = useState([]);
  const [parentBranchId, setParentBranchId] = useState('');
  const [parentBranch, setParentBranch] = useState('');
  const [panNumber, setPanNumber] = useState('');
  const [gstNumber, setGstNumber] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [alternatePhoneNo, setAlternatePhoneNo] = useState('');
  const [allowInscan, setAllowInscan] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [postalcodeId, setpostalcodeId] = useState('');
  const [postalcodes, setpostalcodes] = useState([]);
  const [filteredpostalcodes, setFilteredpostalcodes] = useState([]);
  const [cityId, setcityId] = useState('');
  const [cityName, setCityName] = useState('');
  const [stateName, setStateName] = useState('');
  const [regionName, setRegionName] = useState('');
  const [zoneName, setZoneName] = useState('');
  const [countries, setcountries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editItemName, setEditItemName] = useState('');
  const itemsPerPage = 5;
  const [key, setKey] = useState(0);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const config = require('../../config')
  useEffect(() => {
    fetchBranches();
    fetchpostalcodes();
    fetchVendors();
  }, []);

  const fetchBranches = async () => {
    try {
      const response = await fetch(config.backendDomain + '/parentbranch', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setBranches(data);
      setFilteredBranches(data); // Initialize filtered data with all branches
    } catch (error) {
      console.error('Error fetching branches:', error);
    }
  };

  const handleBranchSearch = (searchTerm) => {
    const filtered = branches.filter((branch) =>
      branch.branch_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    console.log('Filtered Branches:', filtered); // Add this line for debugging
    setFilteredBranches(filtered);
  };

  const fetchpostalcodes = async () => {
    try {
      const response = await fetch(config.backendDomain + '/postalcode', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setpostalcodes(data);
      setFilteredpostalcodes(data);

      // Fetch cities based on postalcodes
      const cityResponse = await fetch(config.backendDomain + '/city', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const cityData = await cityResponse.json();
      setcountries(cityData);
    } catch (error) {
      console.error('Error fetching postalcodes and countries:', error);
    }
  };

  const handlepostalcodeSearch = (searchTerm) => {
    const filtered = postalcodes.filter((postalcode) =>
      postalcode.postalcode_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    setFilteredpostalcodes(filtered);
  };

  const fetchVendors = async () => {
    try {
      const response = await fetch(config.backendDomain + '/Vendor', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      // Sort the data by created_date in descending order
      const sortedData = data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
      setVendors(sortedData);
    } catch (error) {
      console.error('Error fetching Branchs:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate that cityName is not empty
    if (!cityName) {
      showAlert('city name is required');
      return;
    }

    console.log('Request Body:', JSON.stringify({
      VendorName, gstNumber, panNumber, contactPerson, email, phoneNo, alternatePhoneNo, allowInscan,
      address1, address2, postalcodeId, cityId, cityName
    }));

    try {
      const response = await fetch(config.backendDomain + '/Vendor', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          VendorName, parentBranchId, parentBranch, gstNumber, panNumber, contactPerson, email, phoneNo, alternatePhoneNo, allowInscan,
          address1, address2, postalcodeId, cityId, cityName, stateName, regionName, zoneName, countries
        }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        setVendorName('');
        setGstNumber('');
        setPanNumber('');
        setContactPerson('');
        setEmail('');
        setPhoneNo('');
        setAlternatePhoneNo('');
        setAllowInscan('');
        setAddress1('');
        setAddress2('');
        setpostalcodeId('');
        setcityId('');
        setCityName('');
        setStateName('');
        setRegionName('');
        setZoneName('');
        setcountries('');
        fetchVendors();
        setParentBranchId('');
        setParentBranch('');
        // setKey((prevKey) => prevKey + 2);

        // Fetch latest data after successful submission
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error creating Branch:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleEdit = async (id, newName) => {
    try {
      const response = await fetch(config.backendDomain + `/Vendor/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ Vendor: newName }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchVendors(); // Fetch latest data after successful edit
        handleCloseEditPopup();
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error updating Vendor:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(config.backendDomain + `/Vendor/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchVendors(); // Fetch latest data after successful deletion
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error deleting Vendor:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleErrorResponse = (status, message) => {
    if (status === 403) {
      showAlert('You are not authorized.');
    } else if (status === 400) {
      showAlert(message || 'Vendor with the same name already exists');
    } else {
      showAlert('An error occurred.');
    }
  };

  const showAlert = (msg) => {
    toast.info(msg);
  };


  const handleOpenEditPopup = (id, newName) => {
    setEditItemId(id);
    setEditItemName(newName);
    setShowEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setEditItemId(null);
    setEditItemName('');
    setShowEditPopup(false);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Vendors.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div key={key} className="relative bg-gray-100 animate__animated animate__fadeIn">
      <ToastContainer className="mt-14" />
      <div className="container mx-auto py-8 mt-0">
        <div className="relative bg-blue-50">
          <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1">Vendor Gateway</h2>
          <form onSubmit={handleSubmit} className="grid grid-cols-3 gap-4">
            <div className="col-span-1 mb-4 relative">
              <label
                className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                Enter Vendor Name
              </label>
              <input
                type="text"
                id="vendorName"
                className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter Vendor Name"
                value={VendorName}
                onChange={(e) => setVendorName(e.target.value)}
                required
              />
            </div>

            <div className="col-span-1 mb-4 relative">
              <ReactSelect
                id="parentBranchId"
                className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                options={filteredBranches.map((branch) => ({
                  value: branch.branch_id,
                  label: branch.branch_name,
                }))}
                value={filteredBranches.find((branch) => branch.branch_name === parentBranchId)}
                onChange={(selectedOption) => {
                  const selectedBranch = branches.find((branch) => branch.branch_id === selectedOption.value);
                  if (selectedBranch) {
                    setParentBranchId(selectedOption.value);
                    setParentBranch(selectedOption.label);
                  }
                }}
                placeholder="Select Parent Branch"
              />
              <label
                className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                Select Parent Branch
              </label>
            </div>
            <div className="col-span-1 mb-4 relative">
              <label
                className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                Enter GST Number
              </label>
              <input
                type="text"
                id="gstNumber"
                pattern="[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9]{1}"
                title="Please enter a valid GST Number"
                className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter GST Number"
                value={gstNumber}
                onChange={(e) => setGstNumber(e.target.value)}
                required
              />
            </div>

            <div className="col-span-1 mb-4 relative">
              <label
                className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                Enter PAN Number
              </label>
              <input
                type="text"
                id="panNumber"
                pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                title="Please enter a valid GST Number"
                className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter PAN Number"
                value={panNumber}
                onChange={(e) => setPanNumber(e.target.value)}
                required
              />
            </div>

            <div className="col-span-1 mb-4 relative">
              <label
                className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                Enter Contact Person
              </label>
              <input
                type="text"
                id="contactPerson"
                pattern="[A-Za-z]+"
                title="Please enter alphabetic characters only"
                className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter Contact Person"
                value={contactPerson}
                onChange={(e) => setContactPerson(e.target.value)}
                required
              />
            </div>
            <div className="col-span-1 mb-4 relative">
              <label
                className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                Enter Email
              </label>
              <input
                type="email"
                id="email"
                className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="col-span-1 mb-4 relative">
              <label
                className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                Enter Phone No
              </label>
              <input
                type="tel"
                id="phoneNo"
                pattern="[0-9]{10}"
                title="Please enter a 10-digit phone number"
                className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter Phone No."
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                required
              />
            </div>
            <div className="col-span-1 mb-4 relative">
              <label
                className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                Enter alternate Phone No
              </label>
              <input
                type="tel"
                id="alternatePhoneNo"
                pattern="[0-9]{10}"
                title="Please enter a 10-digit phone number"
                className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter alternate Phone No."
                value={alternatePhoneNo}
                onChange={(e) => setAlternatePhoneNo(e.target.value)}
                required
              />
            </div>
            <div className="col-span-1 mb-4 relative">
              <label
                className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                Enter Address1
              </label>
              <input
                type="text"
                id="address1"
                pattern="[a-zA-Z0-9 ]+"
                title="Please enter only letters, numbers, and spaces"
                className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter Address1"
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
                required
              />
            </div>
            <div className="col-span-1 mb-4 relative">
              <label
                className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                Enter Address2
              </label>
              <input
                type="text"
                id="address2"
                pattern="[a-zA-Z0-9 ]+"
                title="Please enter only letters, numbers, and spaces"
                className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter Address2"
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                required
              />
            </div>
            <div className="col-span-1 mb-4 relative">
              <ReactSelect
                id="postalcodeId"
                className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                options={filteredpostalcodes.map((postalcode) => ({
                  value: postalcode.postalcode_id,
                  label: postalcode.postalcode_name,
                }))}
                value={filteredpostalcodes.find((postalcode) => postalcode.postalcode_name === postalcodeId)}
                onChange={(selectedOption) => {
                  const selectedpostalcode = postalcodes.find((postalcode) => postalcode.postalcode_id === selectedOption.value);
                  if (selectedpostalcode) {
                    setpostalcodeId(selectedOption.value);
                    setcityId(selectedpostalcode.city_id);
                    setCityName(selectedpostalcode.city_name);
                    setcountries(selectedpostalcode.country_name);
                    setStateName(selectedpostalcode.state_name);
                    setRegionName(selectedpostalcode.region_name);
                    setZoneName(selectedpostalcode.zone_name);
                  }
                }}
                placeholder="Select Postal Code"
              />
              <label
                className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                Select Postal Code
              </label>
            </div>
            {postalcodeId && ( // Render city select only if postalcode is selected
              <div className="col-span-1 mb-4 flex relative">
                <input
                  type="text"
                  id="cityName"
                  className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Select city"
                  value={cityName}
                  readOnly // Make the input read-only
                />
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Select city
                </label>
              </div>
            )}
            {postalcodeId && ( // Render city select only if postalcode is selected
              <div className="col-span-1 mb-4 flex relative">
                <input
                  type="text"
                  id="regionName"
                  className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Select Region"
                  value={regionName}
                  readOnly // Make the input read-only
                />
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Select Region
                </label>
              </div>
            )}
            {postalcodeId && ( // Render city select only if postalcode is selected
              <div className="col-span-1 mb-4 flex relative">
                <input
                  type="text"
                  id="zoneName"
                  className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Select Zone"
                  value={zoneName}
                  readOnly // Make the input read-only
                />
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Select Zone
                </label>
              </div>
            )}
            {postalcodeId && ( // Render city select only if postalcode is selected
              <div className="col-span-1 mb-4 flex relative">
                <input
                  type="text"
                  id="StateName"
                  className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Select State"
                  value={stateName}
                  readOnly // Make the input read-only
                />
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Select State
                </label>
              </div>
            )}
            {postalcodeId && ( // Render city select only if postalcode is selected
              <div className="col-span-1 mb-4 flex relative">
                <input
                  type="text"
                  id="countryName"
                  className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Select Country"
                  value={countries}
                  readOnly // Make the input read-only
                />
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Select Country
                </label>
              </div>
            )}
            <button
              className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 h-10 pt-1.5 w-48 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Create Vendor
            </button>
          </form>
          <table className="min-w-full divide-y divide-gray-200 mt-6">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Vendor Name </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Parent Branch</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Address </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">city </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">State </th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Modified Date</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentItems.map((vendor) => (
                <tr key={vendor.vendor_id}>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{vendor.vendor_id}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{vendor.vendor_name}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{vendor.parent_branch}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{vendor.address1_name}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{vendor.city_name}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{vendor.state_name}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{vendor.created_date}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">{vendor.modified_date}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-xs">
                    <button
                      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mr-2"
                      onClick={() => handleOpenEditPopup(vendor.vendor_id, vendor.vendor_name)}
                    >
                      Edit
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => handleDelete(vendor.vendor_id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-start items-center mt-4">
            <div>
              {currentPage > 1 && (
                <button
                  className="mr-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                </button>
              )}
            </div>
            <div className="flex space-x-2">
              {[1, 2, 3, 4].map((page) => (
                <button
                  key={page}
                  className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline ${currentPage === page ? 'bg-orange-400' : ''
                    }`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}
            </div>
            <div>
              {currentPage < Math.ceil(Vendors.length / itemsPerPage) && (
                <button
                  className="ml-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <ChevronRightIcon className="h-5 w-5" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Edit Popup */}
      {showEditPopup && (
        <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center ">
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center">
            <div className="relative mt-8 bg-gray-100 w-[800px] p-4 left-24 rounded-lg shadow-lg border border-dark">
              <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1">Edit Vendor</h2>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-1 mb-4 relative">
                  <label
                    className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                    Edit Vendor Name
                  </label>
                  <input
                    type="text"
                    value={editItemName}
                    onChange={(e) => setEditItemName(e.target.value)}
                    className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Edit Vendor Name"
                  />
                </div>
                <div className="col-span-1 mb-4 relative">
                  <ReactSelect
                    id="parentBranchId"
                    className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    options={filteredBranches.map((branch) => ({
                      value: branch.branch_id,
                      label: branch.branch_name,
                    }))}
                    value={filteredBranches.find((branch) => branch.branch_name === parentBranchId)}
                    onChange={(selectedOption) => {
                      const selectedBranch = branches.find((branch) => branch.branch_id === selectedOption.value);
                      if (selectedBranch) {
                        setParentBranchId(selectedOption.value);
                        setParentBranch(selectedOption.label);
                      }
                    }}
                    placeholder="Select Parent Branch"
                  />
                  <label
                    className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                    Select Parent Branch
                  </label>
                </div>
                <div className="col-span-1 mb-4 relative">
                  <label
                    className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                    Enter GST Number
                  </label>
                  <input
                    type="text"
                    id="gstNumber"
                    pattern="[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9]{1}"
                    title="Please enter a valid GST Number"
                    className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter GST Number"
                    value={gstNumber}
                    onChange={(e) => setGstNumber(e.target.value)}
                    required
                  />
                </div>

                <div className="col-span-1 mb-4 relative">
                  <label
                    className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                    Enter PAN Number
                  </label>
                  <input
                    type="text"
                    id="panNumber"
                    pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                    title="Please enter a valid GST Number"
                    className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter PAN Number"
                    value={panNumber}
                    onChange={(e) => setPanNumber(e.target.value)}
                    required
                  />
                </div>

                <div className="col-span-1 mb-4 relative">
                  <label
                    className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                    Enter Contact Person
                  </label>
                  <input
                    type="text"
                    id="contactPerson"
                    pattern="[A-Za-z]+"
                    title="Please enter alphabetic characters only"
                    className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter Contact Person"
                    value={contactPerson}
                    onChange={(e) => setContactPerson(e.target.value)}
                    required
                  />
                </div>
                <div className="col-span-1 mb-4 relative">
                  <label
                    className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                    Enter Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="col-span-1 mb-4 relative">
                  <label
                    className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                    Enter Phone No
                  </label>
                  <input
                    type="tel"
                    id="phoneNo"
                    pattern="[0-9]{10}"
                    title="Please enter a 10-digit phone number"
                    className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter Phone No."
                    value={phoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                    required
                  />
                </div>
                <div className="col-span-1 mb-4 relative">
                  <label
                    className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                    Enter alternate Phone No
                  </label>
                  <input
                    type="tel"
                    id="alternatePhoneNo"
                    pattern="[0-9]{10}"
                    title="Please enter a 10-digit phone number"
                    className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter alternate Phone No."
                    value={alternatePhoneNo}
                    onChange={(e) => setAlternatePhoneNo(e.target.value)}
                    required
                  />
                </div>
                <div className="col-span-1 mb-4 relative">
                  <label
                    className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                    Enter Address1
                  </label>
                  <input
                    type="text"
                    id="address1"
                    pattern="[a-zA-Z0-9 ]+"
                    title="Please enter only letters, numbers, and spaces"
                    className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter Address1"
                    value={address1}
                    onChange={(e) => setAddress1(e.target.value)}
                    required
                  />
                </div>
                <div className="col-span-1 mb-4 relative">
                  <label
                    className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                    Enter Address2
                  </label>
                  <input
                    type="text"
                    id="address2"
                    pattern="[a-zA-Z0-9 ]+"
                    title="Please enter only letters, numbers, and spaces"
                    className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Enter Address2"
                    value={address2}
                    onChange={(e) => setAddress2(e.target.value)}
                    required
                  />
                </div>
                <div className="col-span-1 mb-4 relative">
                  <ReactSelect
                    id="postalcodeId"
                    className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    options={filteredpostalcodes.map((postalcode) => ({
                      value: postalcode.postalcode_id,
                      label: postalcode.postalcode_name,
                    }))}
                    value={filteredpostalcodes.find((postalcode) => postalcode.postalcode_name === postalcodeId)}
                    onChange={(selectedOption) => {
                      const selectedpostalcode = postalcodes.find((postalcode) => postalcode.postalcode_id === selectedOption.value);
                      if (selectedpostalcode) {
                        setpostalcodeId(selectedOption.value);
                        setcityId(selectedpostalcode.city_id);
                        setCityName(selectedpostalcode.city_name);
                        setcountries(selectedpostalcode.country_name);
                        setStateName(selectedpostalcode.state_name);
                        setRegionName(selectedpostalcode.region_name);
                        setZoneName(selectedpostalcode.zone_name);
                      }
                    }}
                    placeholder="Select Postal Code"
                  />
                  <label
                    className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                    Select Postal Code
                  </label>
                </div>
                {postalcodeId && ( // Render city select only if postalcode is selected
                  <div className="col-span-1 mb-4 flex relative">
                    <input
                      type="text"
                      id="cityName"
                      className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="Select city"
                      value={cityName}
                      readOnly // Make the input read-only
                    />
                    <label
                      className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                      Select city
                    </label>
                  </div>
                )}
                {postalcodeId && ( // Render city select only if postalcode is selected
                  <div className="col-span-1 mb-4 flex relative">
                    <input
                      type="text"
                      id="regionName"
                      className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="Select Region"
                      value={regionName}
                      readOnly // Make the input read-only
                    />
                    <label
                      className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                      Select Region
                    </label>
                  </div>
                )}
                {postalcodeId && ( // Render city select only if postalcode is selected
                  <div className="col-span-1 mb-4 flex relative">
                    <input
                      type="text"
                      id="zoneName"
                      className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="Select Zone"
                      value={zoneName}
                      readOnly // Make the input read-only
                    />
                    <label
                      className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                      Select Zone
                    </label>
                  </div>
                )}
                {postalcodeId && ( // Render city select only if postalcode is selected
                  <div className="col-span-1 mb-4 flex relative">
                    <input
                      type="text"
                      id="StateName"
                      className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="Select State"
                      value={stateName}
                      readOnly // Make the input read-only
                    />
                    <label
                      className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                      Select State
                    </label>
                  </div>
                )}
                {postalcodeId && ( // Render city select only if postalcode is selected
                  <div className="col-span-1 mb-4 flex relative">
                    <input
                      type="text"
                      id="countryName"
                      className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="Select Country"
                      value={countries}
                      readOnly // Make the input read-only
                    />
                    <label
                      className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                      Select Country
                    </label>
                  </div>
                )}
              </div>
              <div className="flex justify-end">
                <button
                  onClick={() => handleEdit(editItemId, editItemName)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                >
                  Save Changes
                </button>
                <button
                  onClick={handleCloseEditPopup}
                  className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Vendor;
