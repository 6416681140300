import React, { useState, useEffect } from "react";
import ReactSelect from "react-select"; // Import ReactSelect
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify"; // Moved up
import "react-toastify/dist/ReactToastify.css"; // Moved down

const Waybill = () => {
  const [nextcurrentPage, setNextCurrentPage] = useState(1);
  const [selectedWaybillName, setSelectedWaybillName] = useState("");
  const [Waybills, setWaybills] = useState([]);
  const [branches, setBranches] = useState([]);
  const [filteredBranches, setFilteredBranches] = useState([]);
  const [selectedParentBranchId, setSelectedParentBranchId] = useState("");

  const [paymentmodes, setPaymentModes] = useState([]);
  const [filteredPaymentmodes, setFilteredPaymentmodes] = useState([]);
  const [paymentModeId, setPaymentModeId] = useState("");
  const [selectedPaymentModeName, setSelectedPaymentModeName] = useState("");
  const [transportmodes, setTransportModes] = useState([]);
  const [filteredTransportmodes, setFilteredTransportmodes] = useState([]);
  const [transportModeId, setTransportModeId] = useState("");
  const [transportModeName, setTransportModeName] = useState("");
  const [bookingDate, setBookingDate] = useState(null);
  const [packets, setPackets] = useState("");
  const [actualWeight, setActualWeight] = useState("");
  const [volumeWeight, setVolumeWeight] = useState("");
  const [consignorName, setConsignorName] = useState("");
  const [consignorAddress, setConsignorAddress] = useState("");
  const [consigneeName, setConsigneeName] = useState("");
  const [consigneeAddress, setConsigneeAddress] = useState("");
  const [consignorPhoneNo, setConsignorPhoneNo] = useState("");
  const [consigneePhoneNo, setConsigneePhoneNo] = useState("");
  const [postalcodeId, setpostalcodeId] = useState("");
  const [postalcodes, setpostalCodes] = useState([]);
  const [filteredpostalcodes, setFilteredpostalcodes] = useState([]);
  const [postalcodeName, setPostalcodeName] = useState("");
  const [origincityId, setOriginCityId] = useState("");
  const [origincityName, setOriginCityName] = useState("");
  const [originstateId, setOriginStateId] = useState("");
  const [originstateName, setOriginStateName] = useState("");
  const [originregionId, setOriginRegionId] = useState("");
  const [originregionName, setOriginRegionName] = useState("");
  const [originzoneId, setOriginZoneId] = useState("");
  const [originzoneName, setOriginZoneName] = useState("");
  const [origincountriesId, setOriginCountriesId] = useState("");
  const [origincountries, setOriginCountries] = useState([]);
  const [destcityId, setDestCityId] = useState("");
  const [destcityName, setDestCityName] = useState("");
  const [deststateId, setDestStateId] = useState("");
  const [deststateName, setDestStateName] = useState("");
  const [destregionId, setDestRegionId] = useState("");
  const [destregionName, setDestRegionName] = useState("");
  const [destzoneId, setDestZoneId] = useState("");
  const [destzoneName, setDestZoneName] = useState("");
  const [destcountriesId, setDestCountriesId] = useState("");
  const [destcountries, setDestCountries] = useState([]);
  const [itemValue, setItemValue] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [ewayBill, setEwayBill] = useState("");
  const [ewayExpiryDate, setEwayExpiryDate] = useState("");
  const [bookingAmount, setBookingAmount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editItemName, setEditItemName] = useState("");
  const itemsPerPage = 5;
  const [filteredcustomer, setFilteredcustomer] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [wayBillDropdownOptions, setWayBillDropdownOptions] = useState([]);
  const token = localStorage.getItem("token");
  const config = require("../../config");

  useEffect(() => {
    fetchBranches();
    fetchpostalcodes();
    fetchpaymentmodes();
    fetchtransportmodes();
    fetchWaybills();
    fetchcustomer();
  }, []);

  const fetchBranches = async () => {
    try {
      const response = await fetch(config.backendDomain + "/parentbranch", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setBranches(data);
      setFilteredBranches(data); // Initialize filtered data with all branches
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };
  const fetchcustomer = async () => {
    try {
      const response = await fetch(config.backendDomain + "/customer", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setFilteredcustomer(data);
    } catch (error) {
      console.error("Error fetching Customer:", error);
    }
  };
  const handleWaybillInput = async (event) => {

    const inputVal = event.target.value

    if (inputVal.length > 26) {
      return;
    }
    if (inputVal.length >= 11) {
      try {
        setSelectedWaybillName(inputVal);

        setTimeout(async () => {
          const response = await fetch(
            config.backendDomain + "/purchaseentry/" + inputVal,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ); const data = await response.json();
          setWayBillDropdownOptions(data);
        }, 500)


      } catch (error) {
        console.error("Error fetching waybillId:", error);
      }
    } else {
      setWayBillDropdownOptions([]);
      setSelectedWaybillName(inputVal);
    }
  };

  // const handleBranchSearch = (searchTerm) => {
  //   const filtered = branches.filter((branch) =>
  //     branch.branch_name.toLowerCase().startsWith(searchTerm.toLowerCase())
  //   );
  //   console.log('Filtered Branches:', filtered); // Add this line for debugging
  //   setFilteredBranches(filtered);
  // };

  const fetchpostalcodes = async () => {
    try {
      const response = await fetch(config.backendDomain + "/postalcode", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setpostalCodes(data);
      setFilteredpostalcodes(data);
    } catch (error) {
      console.error("Error fetching postalcodes and countries:", error);
    }
  };

  // const handlepostalcodeSearch = (searchTerm) => {
  //   const filtered = postalcodes.filter((postalcode) =>
  //     postalcode.postalcode_name.toLowerCase().startsWith(searchTerm.toLowerCase())
  //   );
  //   setFilteredpostalcodes(filtered);
  // };

  const fetchpaymentmodes = async () => {
    try {
      const response = await fetch(config.backendDomain + "/paymentmode", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setPaymentModes(data);
      setFilteredPaymentmodes(data); // Initialize filtered data with all branches
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  // const handlePaymentModeSearch = (searchTerm) => {
  //   const filtered = paymentmodes.filter((paymentmode) =>
  //     paymentmode.paymentmode_name.toLowerCase().startsWith(searchTerm.toLowerCase())
  //   );
  //   console.log('Filtered PaymentMode:', filtered); // Add this line for debugging
  //   setFilteredPaymentmodes(filtered);
  // };

  const fetchtransportmodes = async () => {
    try {
      const response = await fetch(config.backendDomain + "/transportmode", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setTransportModes(data);
      setFilteredTransportmodes(data); // Initialize filtered data with all branches
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  // const handleTransportModeSearch = (searchTerm) => {
  //   const filtered = transportmodes.filter((transportmode) =>
  //     transportmode.transportmode_name.toLowerCase().startsWith(searchTerm.toLowerCase())
  //   );
  //   console.log('Filtered TransportMode:', filtered); // Add this line for debugging
  //   setFilteredTransportmodes(filtered);
  // };

  const fetchWaybills = async () => {
    try {
      const response = await fetch(config.backendDomain + "/booking", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      // Sort the data by created_date in descending order
      const sortedData = data.sort(
        (a, b) => new Date(b.created_date) - new Date(a.created_date)
      );
      setWaybills(sortedData);
    } catch (error) {
      console.error("Error fetching Branchs:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(config.backendDomain + "/booking", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          waybillName: selectedWaybillName,
          parentBranchId: selectedParentBranchId.value,
          parentBranch: selectedParentBranchId.label,
          bookingDate,
          paymentModeId: selectedPaymentModeName.value,
          paymentModeName: selectedPaymentModeName.label,
          transportModeId,
          transportModeName,
          packets,
          actualWeight,
          volumeWeight,
          consignorName,
          consignorAddress,
          consignorPhoneNo,
          postalcodeId,
          postalcodeName,
          origincityId,
          origincityName,
          originstateId,
          originstateName,
          originregionId,
          originregionName,
          originzoneId,
          originzoneName,
          origincountriesId,
          origincountries,
          consigneeName,
          consigneeAddress,
          consigneePhoneNo,
          destcityId,
          destcityName,
          deststateId,
          deststateName,
          destregionId,
          destregionName,
          destzoneId,
          destzoneName,
          destcountriesId,
          destcountries,
          itemValue,
          invoiceNumber,
          gstNumber,
          ewayBill,
          ewayExpiryDate,
          bookingAmount,
          customer: selectedCustomer.value,
        }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        setSelectedWaybillName({});
        setSelectedParentBranchId("");

        setBookingDate("");
        setPaymentModeId("");
        setSelectedPaymentModeName("");
        setTransportModeId("");
        setTransportModeName("");
        setPackets("");
        setActualWeight("");
        setVolumeWeight("");
        setConsignorName("");
        setConsignorPhoneNo("");
        setConsignorAddress("");
        setpostalcodeId("");
        setPostalcodeName("");
        setOriginCityId("");
        setOriginCityName("");
        setOriginStateId("");
        setOriginStateName("");
        setOriginRegionId("");
        setOriginRegionName("");
        setOriginZoneId("");
        setOriginZoneName("");
        setOriginCountriesId("");
        setOriginCountries("");
        setConsigneeAddress("");
        setConsigneeName("");
        setConsigneePhoneNo("");
        setDestCityId("");
        setDestCityName("");
        setDestStateId("");
        setDestStateName("");
        setDestRegionId("");
        setDestRegionName("");
        setDestZoneId("");
        setDestZoneName("");
        setDestCountriesId("");
        setDestCountries("");
        setItemValue("");
        setInvoiceNumber("");
        setGstNumber("");
        setEwayBill("");
        setEwayExpiryDate("");
        setBookingAmount("");
        fetchpostalcodes("");
        fetchWaybills("");
        setSelectedCustomer("");
        // setKey((prevKey) => prevKey + 2);
        setNextCurrentPage(1);

        // Fetch latest data after successful submission
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error("Error creating Branch:", error);
      handleErrorResponse(500, "An error occurred."); // Internal server error
    }
  };

  const handleErrorResponse = (status, message) => {
    if (status === 403) {
      showAlert("You are not authorized.");
    } else if (status === 400) {
      showAlert(message || "Waybill with the same name already exists");
    } else {
      showAlert("An error occurred.");
    }
  };

  const showAlert = (msg) => {
    toast.info(msg);
  };

  const handleNextPage = () => {
    setNextCurrentPage(nextcurrentPage + 1);
  };

  const handlePreviousPage = () => {
    setNextCurrentPage(nextcurrentPage - 1);
  };

  const handleEdit = async (id, newName) => {
    try {
      const response = await fetch(config.backendDomain + `/booking/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ branchName: newName }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchWaybills(); // Fetch latest data after successful edit
        handleCloseEditPopup();
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error("Error updating Branch:", error);
      handleErrorResponse(500, "An error occurred."); // Internal server error
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(config.backendDomain + `/booking/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchWaybills(); // Fetch latest data after successful deletion
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error("Error deleting Branch:", error);
      handleErrorResponse(500, "An error occurred."); // Internal server error
    }
  };

  const handleOpenEditPopup = (id, newName) => {
    setEditItemId(id);
    setEditItemName(newName);
    setShowEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setEditItemId(null);
    setEditItemName("");
    setShowEditPopup(false);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Waybills.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleliChange = (value) => {
    console.log(value)
    setWayBillDropdownOptions([]);
    if (value.customerId) {
      setSelectedCustomer(value.customerId)
    }
    if (value.gstnumber_name) {
      setGstNumber(value.gstnumber_name)
    }
    if (value.branch_name && value.branch_id) {
      setSelectedParentBranchId({ value: value.branch_id, label: value.branch_name });
      // setParentBranch(value.branch_name);
    }

  }

  return (

    <div className="relative bg-blue-50 animate__animated animate__fadeIn">
      <ToastContainer className="mt-14" />
      <div className="relative bg-blue-50">
        <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1">
          Booking Waybill Gateway
        </h2>
      </div>
      {nextcurrentPage === 1 && (
        <div className="grid grid-cols-2 gap-2">
          <div className="mb-2 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Enter Waybill Number
            </label>
            <input
              type="text"
              id="wayBill"
              className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter Waybill Number"
              value={selectedWaybillName}
              onChange={handleWaybillInput}
              autoComplete="off"

              required
            />
            {wayBillDropdownOptions.length > 0 && (
              //    <ReactSelect
              //    id="waybill"
              //    options={wayBillDropdownOptions.map((option) => ({
              //      value: option,
              //      label: option.start_from+' - '+ option.end_to,
              //    }))}
              //   //  value=
              //    //onChange=
              //  />

              <ul
                style={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  width: "100%",
                  maxHeight: "150px",
                  overflowY: "auto",
                  backgroundColor: "#fff",
                  border: "1px solid #ccc",
                  margin: 0,
                  padding: 0,
                  listStyleType: "none",
                  zIndex: 1000,
                }}
              >
                {wayBillDropdownOptions.map((option, index) => (
                  <li key={index} style={{ padding: '8px', cursor: 'pointer' }} onClick={() => { handleliChange(option) }}>
                    {option.start_from + " - " + option.end_to + ', ' + option.parent_branch + ', ' + option.product_name}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="mb-2 relative">
            <DatePicker
              selected={bookingDate}
              onChange={(date) => setBookingDate(date)}
              dateFormat="MM/dd/yyyy"
              placeholderText="Select Booking Date"
              className=" shadow-lg w-[610px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Select Booking Date
            </label>
          </div>

          <div className="mb-2 relative ">
            <ReactSelect
              id="customerId"
              className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              options={filteredcustomer.map((customer) => ({
                value: customer.customer_id,
                label: customer.customer_name,
              }))}
              value={selectedCustomer}
              onChange={setSelectedCustomer}
              placeholder="Select Customer / Clients"
            />
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Select Customer / Clients
            </label>
          </div>

          <div className="mb-2 relative">
            <ReactSelect
              id="parentBranchId"
              className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              options={filteredBranches.map((branch) => ({
                value: branch.branch_id,
                label: branch.branch_name,
              }))}
              value={selectedParentBranchId}
              onChange={setSelectedParentBranchId}
              placeholder="Select Booking Branch"
            />
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Select Booking Branch
            </label>
          </div>

          <div className="mb-2 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              No of Packets
            </label>
            <input
              type="Number"
              id="packets"
              className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="No of Packets"
              value={packets}
              onChange={(e) => setPackets(e.target.value)}
              required
            />
          </div>

          <div className="mb-2 relative">
            <ReactSelect
              id="transportmodeId"
              className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              options={filteredTransportmodes.map((transportmode) => ({
                value: transportmode.transportmode_id,
                label: transportmode.transportmode_name,
              }))}
              value={filteredTransportmodes.find(
                (transportmode) =>
                  transportmode.transportmode_name === transportModeId
              )}
              onChange={(selectedOption) => {
                const selectedTransportMode = transportmodes.find(
                  (transportmode) =>
                    transportmode.transportmode_id === selectedOption.value
                );
                if (selectedTransportMode) {
                  setTransportModeId(selectedOption.value);
                  setTransportModeName(selectedOption.label);
                }
              }}
              placeholder="Select Transport Mode"
            />
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Select Transport Mode
            </label>
          </div>

          <div className="mb-2 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Actual Weight
            </label>
            <input
              type="Number"
              id="paymentMode"
              className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Actual Weight"
              value={actualWeight}
              onChange={(e) => setActualWeight(e.target.value)}
              required
            />
          </div>

          <div className="mb-2 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Volumteric Weight
            </label>
            <input
              type="Number"
              id="paymentMode"
              className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Volumteric Weight"
              value={volumeWeight}
              onChange={(e) => setVolumeWeight(e.target.value)}
              required
            />
          </div>

          <div className="mb-2 relative">
            <ReactSelect
              id="paymentmodeId"
              className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              options={filteredPaymentmodes.map((paymentmode) => ({
                value: paymentmode.paymentmode_id,
                label: paymentmode.paymentmode_name,
              }))}
              value={selectedPaymentModeName}
              onChange={setSelectedPaymentModeName}
              placeholder="Select Payment Mode"
            />
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Select Payment Mode
            </label>
          </div>

          <div className="mb-2 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Booking Amount
            </label>
            <input
              type="Number"
              id="amount"
              className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Booking Amount"
              value={bookingAmount}
              onChange={(e) => setBookingAmount(e.target.value)}
              required
            />
          </div>

          <div className="mb-2 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Invoice Number
            </label>
            <input
              type="text"
              id="invnumber"
              className=" shadow-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Invoice Number"
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
              required
            />
          </div>


          <div className="mb-2 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Declared Value
            </label>
            <input
              type="Number"
              id="itemvalue"
              className=" shadow-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Declared Value"
              value={itemValue}
              onChange={(e) => setItemValue(e.target.value)}
              required
            />
          </div>



          <div className="col-span-1 mb-2 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Enter GST Number
            </label>
            <input
              type="text"
              id="gstNumber"
              pattern="[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9]{1}"
              title="Please enter a valid GST Number"
              className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter GST Number"
              value={gstNumber}
              onChange={(e) => setGstNumber(e.target.value)}
              required
            />
          </div>


          <div className="col-span-1 mb-2 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Enter EWAY Bill Number
            </label>
            <input
              type="text"
              id="gstNumber"
              pattern="[0-9]{12}"
              title="Please enter a valid EWAY Bill Number"
              className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter EWAY Bill Number"
              value={ewayBill}
              onChange={(e) => setEwayBill(e.target.value)}
              required
            />
          </div>




          <div className="mb-2 relative">
            <DatePicker
              selected={ewayExpiryDate}
              onChange={(date) => setEwayExpiryDate(date)}
              dateFormat="MM/dd/yyyy"
              placeholderText="Eway Bill Expiry Date"
              className="shadow-lg w-[610px]  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Eway Bill Expiry Date
            </label>
          </div>

          <button
            className="next-btn w-48 h-10 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
            onClick={handleNextPage}
          >
            Next
          </button>
        </div>
      )}
      <div className="absolute top-[565px] bottom-0 left-0 right-0">
        <div className="h-[500px] overflow-y-auto">


          <div className="flex justify-start items-center mt-4">
            <div>
              {currentPage > 1 && (
                <button
                  className="mr-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                </button>
              )}
            </div>

            <div>
              {currentPage < Math.ceil(Waybills.length / itemsPerPage) && (
                <button
                  className="ml-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <ChevronRightIcon className="h-5 w-5" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Edit Popup
      {showEditPopup && (
        <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
          <div className="relative bg-white w-96 rounded-lg p-8">
            <h2 className="text-lg font-bold mb-4">Edit waybill Name</h2>
            <input
              type="text"
              value={editItemName}
              onChange={(e) => setEditItemName(e.target.value)}
              className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter New waybill Name"
            />
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => handleEdit(editItemId, editItemName)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
              >
                Save Changes
              </button>
              <button
                onClick={handleCloseEditPopup}
                className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )} */}

      {nextcurrentPage === 2 && (
        <div className="grid grid-cols-2 gap-2">
          <div className="mb-2 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Consignor Name
            </label>
            <input
              type="text"
              id="branchParent"
              className=" shadow-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Consignor Name"
              value={consignorName}
              onChange={(e) => setConsignorName(e.target.value)}
              required
            />
          </div>

          <div className="mb-2 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Consignee Name
            </label>
            <input
              type="text"
              id="contactPerson"
              className=" shadow-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Consignee Name"
              value={consigneeName}
              onChange={(e) => setConsigneeName(e.target.value)}
              required
            />
          </div>
          <div className="mb-2 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Consignor Address
            </label>
            <input
              type="text"
              id="address"
              className=" shadow-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Consignor Address"
              value={consignorAddress}
              onChange={(e) => setConsignorAddress(e.target.value)}
              required
            />
          </div>
          <div className="mb-2 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Consignee Address
            </label>
            <input
              type="text"
              id="address"
              className=" shadow-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Consignee Address"
              value={consigneeAddress}
              onChange={(e) => setConsigneeAddress(e.target.value)}
              required
            />
          </div>
          <div className="col-span-1 mb-2 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Sender Phone No
            </label>
            <input
              type="tel"
              id="phoneNo"
              pattern="[0-9]{10}"
              title="Please enter a 10-digit phone number"
              className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Sender Phone No."
              value={consignorPhoneNo}
              onChange={(e) => setConsignorPhoneNo(e.target.value)}
              required
            />
          </div>

          <div className="col-span-1 mb-2 relative">
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Reciever Phone No
            </label>
            <input
              type="tel"
              id="phoneNo"
              pattern="[0-9]{10}"
              title="Please enter a 10-digit phone number"
              className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Reciever Phone No."
              value={consigneePhoneNo}
              onChange={(e) => setConsigneePhoneNo(e.target.value)}
              required
            />
          </div>
          <div className="col-span-1 mb-2 relative">
            <ReactSelect
              id="postalcodeId"
              className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              options={filteredpostalcodes.map((postalcode) => ({
                value: postalcode.postalcode_id,
                label: postalcode.postalcode_name,
              }))}
              value={filteredpostalcodes.find(
                (postalcode) => postalcode.postalcode_name === postalcodeId
              )}
              onChange={(selectedOption) => {
                const selectedpostalcode = postalcodes.find(
                  (postalcode) =>
                    postalcode.postalcode_id === selectedOption.value
                );
                if (selectedpostalcode) {
                  setpostalcodeId(selectedOption.value);
                  setPostalcodeName(selectedOption.label);
                  setOriginCityId(selectedpostalcode.city_id);
                  setOriginCityName(selectedpostalcode.city_name);
                  setOriginStateId(selectedpostalcode.state_id);
                  setOriginStateName(selectedpostalcode.state_name);
                  setOriginRegionId(selectedpostalcode.region_id);
                  setOriginRegionName(selectedpostalcode.region_name);
                  setOriginZoneId(selectedpostalcode.zone_id);
                  setOriginZoneName(selectedpostalcode.zone_name);
                  setOriginCountriesId(selectedpostalcode.country_id);
                  setOriginCountries(selectedpostalcode.country_name);
                }
              }}
              placeholder="Origin Postal Code"
            />
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Origin Postal Code
            </label>

            {postalcodeId && ( // Render city select only if postalcode is selected
              <div className="col-span-1 mb-2 flex relative">
                <input
                  type="text"
                  id="cityName"
                  className="shadow-lg w-full mt-4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Select city"
                  value={origincityName}
                  readOnly // Make the input read-only
                />
                <label
                  className="absolute bottom-7 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Origin city
                </label>
              </div>
            )}
            {postalcodeId && ( // Render city select only if postalcode is selected
              <div className="col-span-1 mb-2 flex relative">
                <input
                  type="text"
                  id="StateName"
                  className="shadow-lg w-full mt-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Select State"
                  value={originstateName}
                  readOnly // Make the input read-only
                />
                <label
                  className="absolute top-0 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Origin State
                </label>
              </div>
            )}
            {postalcodeId && ( // Render city select only if postalcode is selected
              <div className="col-span-1 mb-2 flex relative">
                <input
                  type="text"
                  id="RegionName"
                  className="shadow-lg w-full mt-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Select Region"
                  value={originregionName}
                  readOnly // Make the input read-only
                />
                <label
                  className="absolute top-0 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Origin Region
                </label>
              </div>
            )}
            {postalcodeId && ( // Render city select only if postalcode is selected
              <div className="col-span-1 mb-2 flex relative">
                <input
                  type="text"
                  id="ZoneName"
                  className="shadow-lg w-full mt-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Select Zone"
                  value={originzoneName}
                  readOnly // Make the input read-only
                />
                <label
                  className="absolute top-0 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Origin Zone
                </label>
              </div>
            )}
            {postalcodeId && ( // Render city select only if postalcode is selected
              <div className="col-span-1 mb-2 flex relative">
                <input
                  type="text"
                  id="countryName"
                  className="shadow-lg w-full mt-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Select Country"
                  value={origincountries}
                  readOnly // Make the input read-only
                />
                <label
                  className="absolute top-0 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Origin Country
                </label>
              </div>
            )}
          </div>

          <div className="col-span-1 mb-2 relative">
            <ReactSelect
              id="postalcodeId"
              className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              options={filteredpostalcodes.map((postalcode) => ({
                value: postalcode.postalcode_id,
                label: postalcode.postalcode_name,
              }))}
              value={filteredpostalcodes.find(
                (postalcode) => postalcode.postalcode_name === postalcodeId
              )}
              onChange={(selectedOption) => {
                const selectedpostalcode = postalcodes.find(
                  (postalcode) =>
                    postalcode.postalcode_id === selectedOption.value
                );
                if (selectedpostalcode) {
                  setpostalcodeId(selectedOption.value);
                  setPostalcodeName(selectedOption.label);
                  setDestCityId(selectedpostalcode.city_id);
                  setDestCityName(selectedpostalcode.city_name);
                  setDestStateId(selectedpostalcode.state_id);
                  setDestStateName(selectedpostalcode.state_name);
                  setDestRegionId(selectedpostalcode.region_id);
                  setDestRegionName(selectedpostalcode.region_name);
                  setDestZoneId(selectedpostalcode.zone_id);
                  setDestZoneName(selectedpostalcode.zone_name);
                  setDestCountriesId(selectedpostalcode.country_id);
                  setDestCountries(selectedpostalcode.country_name);
                }
              }}
              placeholder="Destination Postal Code"
            />
            <label
              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
              Destination Postal Code
            </label>

            {postalcodeId && ( // Render city select only if postalcode is selected
              <div className="col-span-1 mb-2 flex relative">
                <input
                  type="text"
                  id="cityName"
                  className="shadow-lg w-full mt-4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Select city"
                  value={destcityName}
                  readOnly // Make the input read-only
                />
                <label
                  className="absolute bottom-7 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Destination city
                </label>
              </div>
            )}
            {postalcodeId && ( // Render city select only if postalcode is selected
              <div className="col-span-1 mb-2 flex relative">
                <input
                  type="text"
                  id="StateName"
                  className="shadow-lg w-full mt-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Select State"
                  value={deststateName}
                  readOnly // Make the input read-only
                />
                <label
                  className="absolute top-0 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Destination State
                </label>
              </div>
            )}
            {postalcodeId && ( // Render city select only if postalcode is selected
              <div className="col-span-1 mb-2 flex relative">
                <input
                  type="text"
                  id="regionName"
                  className="shadow-lg w-full mt-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Select Region"
                  value={destregionName}
                  readOnly // Make the input read-only
                />
                <label
                  className="absolute top-0 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Destination Region
                </label>
              </div>
            )}
            {postalcodeId && ( // Render city select only if postalcode is selected
              <div className="col-span-1 mb-2 flex relative">
                <input
                  type="text"
                  id="zoneName"
                  className="shadow-lg w-full mt-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Select Zone"
                  value={destzoneName}
                  readOnly // Make the input read-only
                />
                <label
                  className="absolute top-0 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Destination Zone
                </label>
              </div>
            )}
            {postalcodeId && ( // Render city select only if postalcode is selected
              <div className="col-span-1 mb-2 flex relative">
                <input
                  type="text"
                  id="countryName"
                  className="shadow-lg w-full mt-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Select Country"
                  value={destcountries}
                  readOnly // Make the input read-only
                />
                <label
                  className="absolute top-0 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm">
                  Destination Country
                </label>
              </div>
            )}
          </div>

          <form onSubmit={handleSubmit} className="grid grid-cols-4 gap-2">
            {/* Add your form fields here */}
            <button
              type="submit"
              className="next-btn bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
            >
              Submit
            </button>
            <button
              onClick={handlePreviousPage}
              className="next-btn bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
            >
              Previous
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Waybill;
