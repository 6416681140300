import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserProfile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [passwords, setPasswords] = useState({ oldPassword: '', newPassword: '' });
  const [loading, setLoading] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState({ oldPassword: false, newPassword: false });

  const config = require('../config');

  // Fetch profile data on component mount
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(config.backendDomain + '/profile', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });

        if (!response.ok) {
          toast.success(data.message);
        }

        const data = await response.json();
        setProfileImage(data.profilePic || '/default-profile.png');
        setUsername(data.user_name || 'User');
        setRole(data.role || 'Guest');
      } catch (error) {
        console.error(error.message);
        toast.error('Unable to load profile. Please try again later.');
      }
    };

    fetchProfile();
  }, [config]);

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleUploadImage = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('profilePic', file);

    try {
      const response = await fetch(config.backendDomain + '/uploadprofilepic', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to upload profile picture');
      }

      const data = await response.json();
      setProfileImage(data.profilePic);
      toast.success('Profile picture updated successfully');
    } catch (error) {
      console.error(error.message);
      toast.error('Error uploading profile picture');
    }
  };

  const handleLogout = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
      toast.error('No token found. You are already logged out.');
      return;
    }

    try {
      const response = await fetch(config.backendDomain + '/logout', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to log out');
      }

      toast.success('Logged out successfully');
      localStorage.removeItem('token');
      setTimeout(() => {
        window.location.href = '/signin';
      }, 2000);
    } catch (error) {
      console.error(error.message);
      toast.error('Error logging out. Please try again.');
    }
  };

  const handleChangePassword = async () => {
    if (!passwords.oldPassword || !passwords.newPassword) {
      toast.error('Both current and new passwords are required');
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${config.backendDomain}/changepassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          currentPassword: passwords.oldPassword,
          newPassword: passwords.newPassword,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to change password');
      }

      const successData = await response.json();
      toast.success(successData.message || 'Password changed successfully');
      setPasswords({ oldPassword: '', newPassword: '' });
      setShowPasswordFields(false);
    } catch (error) {
      console.error('Error: ', error.message);
      toast.error(error.message || 'Failed to change password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="relative flex items-center"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <ToastContainer className="mt-14" />
      <div className="mr-4">
        <p className="text-sm text-gray-300">{username}</p>
        <p className="text-xs text-gray-300">{role}</p>
      </div>
      <div className="h-12 w-12 mt-2 rounded-full bg-gray-100 p-1 flex justify-center items-center cursor-pointer">
        <img
          src={profileImage}
          alt="Profile"
          className="h-full w-full object-cover rounded-full"
        />
      </div>
      {isOpen && (
        <div className="absolute top-14 right-0 w-64 bg-white rounded-lg shadow-lg py-3 z-10">
          <label className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 cursor-pointer">
            <input
              type="file"
              className="hidden"
              onChange={handleUploadImage}
            />
            Upload Profile Picture
          </label>
          <button
            className="block w-full px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 text-left"
            onClick={() => setShowPasswordFields(!showPasswordFields)}
          >
            Change Password
          </button>
          {showPasswordFields && (
            <div className="px-4 py-2">
              <div className="relative mb-2">
                <input
                  type={passwordVisibility.oldPassword ? 'text' : 'password'}
                  placeholder="Old Password"
                  value={passwords.oldPassword}
                  onChange={(e) => setPasswords({ ...passwords, oldPassword: e.target.value })}
                  className="w-full p-2 border rounded text-sm"
                />
                <span
                  className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                  onClick={() => togglePasswordVisibility('oldPassword')}
                >
                  {passwordVisibility.oldPassword ? '👁️' : '👁️‍🗨️'}
                </span>
              </div>
              <div className="relative mb-2">
                <input
                  type={passwordVisibility.newPassword ? 'text' : 'password'}
                  placeholder="New Password"
                  value={passwords.newPassword}
                  onChange={(e) => setPasswords({ ...passwords, newPassword: e.target.value })}
                  className="w-full p-2 border rounded text-sm"
                />
                <span
                  className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                  onClick={() => togglePasswordVisibility('newPassword')}
                >
                  {passwordVisibility.newPassword ? '👁️' : '👁️‍🗨️'}
                </span>
              </div>
              <button
                onClick={handleChangePassword}
                disabled={loading}
                className={`w-full px-4 py-2 text-sm rounded ${loading ? 'bg-gray-400' : 'bg-green-500 text-white hover:bg-green-600'
                  }`}
              >
                {loading ? 'Updating...' : 'Update Password'}
              </button>
            </div>
          )}
          <button
            className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 w-full text-left"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
