import SyncIcon from '@mui/material/CircularProgress';

const Loader = () => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
      <SyncIcon style={{ color: 'white' }} size={32} thickness={6} />
    </div>
  );
};

export default Loader;
