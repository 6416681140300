import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import ReactSelect from 'react-select'; // Import ReactSelect
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify'; // Moved up
import 'react-toastify/dist/ReactToastify.css'; // Moved down
const config = require('../../config')
const City = () => {
  const [cityName, setCityName] = useState('');
  const [stateId, setstateId] = useState('');
  const [states, setstates] = useState([]);
  const [regionId, setregionId] = useState('');
  const [regionName, setRegionName] = useState('');
  const [countries, setcountries] = useState([]);
  const [filteredstates, setFilteredstates] = useState([]);
  const [Citys, setCitys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editItemName, setEditItemName] = useState('');
  const itemsPerPage = 5;
  const [key, setKey] = useState(0);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchstates(); // Fetch initial data when component mounts
    fetchCitys();
  }, []);

  const fetchstates = async () => {
    try {
      const response = await fetch(config.backendDomain + '/state', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setstates(data);
      setFilteredstates(data);

      // Fetch countries based on states
      const regionResponse = await fetch(config.backendDomain + '/region', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const regionData = await regionResponse.json();
      setcountries(regionData);
    } catch (error) {
      console.error('Error fetching states and countries:', error);
    }
  };

  const handlestateSearch = (searchTerm) => {
    const filtered = states.filter((state) =>
      state.state_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    setFilteredstates(filtered);
  };

  const fetchCitys = async () => {
    try {
      const response = await fetch(config.backendDomain + '/City', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      // Sort the data by created_date in descending order
      const sortedData = data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
      setCitys(sortedData);
    } catch (error) {
      console.error('Error fetching Citys:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate that regionName is not empty
    if (!regionName) {
      showAlert('region name is required');
      return;
    }

    console.log('Request Body:', JSON.stringify({ cityName, stateId, regionId, regionName }));

    try {
      const response = await fetch(config.backendDomain + '/City', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ cityName, stateId, regionId, regionName }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        setCityName('');
        setstateId('');
        setregionId('');
        setRegionName('');
        fetchCitys();
        // setKey((prevKey) => prevKey + 1);
        // Fetch latest data after successful submission
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error creating City:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleEdit = async (id, newName) => {
    try {
      const response = await fetch(config.backendDomain + `/City/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ cityName: newName }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchCitys(); // Fetch latest data after successful edit
        handleCloseEditPopup();
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error updating City:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(config.backendDomain + `/City/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchCitys(); // Fetch latest data after successful deletion
      } else if (response.status === 400) {
        showAlert(data.message); // Show associated data error message
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error deleting City:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleErrorResponse = (status, message) => {
    if (status === 403) {
      showAlert('You are not authorized.');
    } else if (status === 400) {
      showAlert(message || 'City with the same name already exists');
    } else {
      showAlert('An error occurred.');
    }
  };

  const showAlert = (msg) => {
    toast.info(msg);
  };

  const handleOpenEditPopup = (id, newName) => {
    setEditItemId(id);
    setEditItemName(newName);
    setShowEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setEditItemId(null);
    setEditItemName('');
    setShowEditPopup(false);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Citys.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div key={key} className="relative bg-gray-100 animate__animated animate__fadeIn">
      <ToastContainer className="mt-14" />
      <div className="container mx-auto py-8 mt-0">
        <div className="relative bg-blue-50">
          <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1">City Gateway</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3 relative">
              <label
                className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
              >
                Enter City Name
              </label>
              <input
                type="text"
                id="cityName"
                pattern="[a-zA-Z0-9 ]+"
                title="Please enter only letters, numbers, and spaces"
                className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter City Name"
                value={cityName}
                onChange={(e) => setCityName(e.target.value)}
                required
              />
            </div>
            <div className="mb-3 relative">

              <ReactSelect
                id="stateId"
                className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                options={filteredstates.map((state) => ({
                  value: state.state_id,
                  label: state.state_name,
                }))}
                value={filteredstates.find((state) => state.state_name === stateId)}
                onChange={(selectedOption) => {
                  const selectedstate = states.find((state) => state.state_id === selectedOption.value);
                  if (selectedstate) {
                    setstateId(selectedOption.value);
                    setregionId(selectedstate.region_id);
                    setRegionName(selectedstate.region_name);
                  }
                }}
                placeholder="Select State"
              />
              <label
                className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
              >
                Select State
              </label>
            </div>
            {stateId && ( // Render region select only if state is selected
              <div className="mb-3 relative">
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Select Region
                </label>
                <input
                  type="text"
                  id="regionName"
                  className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Select Region"
                  value={regionName}
                  readOnly // Make the input read-only
                />
              </div>
            )}
            <button
              className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Create City
            </button>
          </form>
          <table className="min-w-full divide-y divide-gray-200 mt-6">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">City ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">City </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">State </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Region </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Zone </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Country </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Modified Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentItems.map((city) => (
                <tr key={city.city_id}>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{city.city_id}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{city.city_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{city.state_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{city.region_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{city.zone_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{city.country_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{city.created_date}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{city.modified_date}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">
                    <button
                      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mr-2"
                      onClick={() => handleOpenEditPopup(city.city_id, city.city_name)}
                    >
                      Edit
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => handleDelete(city.city_id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-start items-center mt-4">
            <div>
              {currentPage > 1 && (
                <button
                  className="mr-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                </button>
              )}
            </div>
            <div className="flex space-x-2">
              {[1, 2, 3, 4].map((page) => (
                <button
                  key={page}
                  className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline ${currentPage === page ? 'bg-orange-400' : ''
                    }`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}
            </div>
            <div>
              {currentPage < Math.ceil(Citys.length / itemsPerPage) && (
                <button
                  className="ml-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <ChevronRightIcon className="h-5 w-5" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Edit Popup */}
      {showEditPopup && (
        <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center ">
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center">
            <div className="relative mt-8 bg-gray-100 w-[800px] p-4 left-24 rounded-lg shadow-lg border border-dark">
              <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1">Edit City</h2>
              <div className="mb-3 relative">
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Edit City Name
                </label>
                <input
                  type="text"
                  value={editItemName}
                  onChange={(e) => setEditItemName(e.target.value)}
                  className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Edit City Name"
                />
              </div>

              <div className="mb-3 relative">

                <ReactSelect
                  id="stateId"
                  className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  options={filteredstates.map((state) => ({
                    value: state.state_id,
                    label: state.state_name,
                  }))}
                  value={filteredstates.find((state) => state.state_name === stateId)}
                  onChange={(selectedOption) => {
                    const selectedstate = states.find((state) => state.state_id === selectedOption.value);
                    if (selectedstate) {
                      setstateId(selectedOption.value);
                      setregionId(selectedstate.region_id);
                      setRegionName(selectedstate.region_name);
                    }
                  }}
                  placeholder="Select State"
                />
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Select State
                </label>
              </div>
              {stateId && ( // Render region select only if state is selected
                <div className="mb-3 relative">
                  <label
                    className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                  >
                    Select Region
                  </label>
                  <input
                    type="text"
                    id="regionName"
                    className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Select Region"
                    value={regionName}
                    readOnly // Make the input read-only
                  />
                </div>
              )}

              <div className="mt-4 flex justify-end">
                <button
                  onClick={() => handleEdit(editItemId, editItemName)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                >
                  Save Changes
                </button>
                <button
                  onClick={handleCloseEditPopup}
                  className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default City;
