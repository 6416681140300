import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const config = require("../../config");
const Invoice = () => {

  const [invoice, setInvoice] = useState({})

  // const [InvoiceNumber, setInvoiceNumber] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const token = localStorage.getItem("token");
  useEffect(() => {
    fetchinvoice();
  }, []);
  const table = {
    "InvoiceNumber": "id",
    "Customer/Client": "customer_name",
    "Invoice created": "created_at",
    "Amount": "total_amount",
  };
  const billTable = {
    "WayBill Id": "waybill_id",
    "Origin": "origincity_name",
    "Destination": "destcity_name",
    "Actual Weight": "actual_weight",
    "Mode": "transportmode_name",
    "Pcs": "packets",
    "Weight": "actual_weight",

    "Booking Date": "booking_date",
    "Booking Amount": "booking_amount",

  }
  const fetchinvoice = async () => {
    try {
      const response = await fetch(config.backendDomain + "/invoice", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      const groupInvoice = {}
      // setcustomer(data);
      for (let i = 0; i < data.length; i++) {
        const currEle = data[i]
        if (groupInvoice[currEle.id]) {
          groupInvoice[currEle.id].push(currEle)
        } else {
          groupInvoice[currEle.id] = [currEle]
        }
      }
      // console.log(groupInvoice)
      setInvoice(groupInvoice);
    } catch (error) {
      console.error("Error fetching Customer:", error);
    }
  };

  const InvoiceComponent = ({ invoice, isOpen, onClose }) => {
    console.log(invoice)
    const styles = {
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '80px'
      },
      modal: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '4px',
        width: '70vw',
        position: 'relative',
      },
      closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        border: 'none',
        background: 'none',
        fontSize: '16px',
        cursor: 'pointer',
      },
    };
    if (!isOpen) return null;

    return (
      <div style={styles.overlay}>
        <div style={styles.modal} >
          <button style={styles.closeButton} onClick={onClose} >X</button>

          <div style={{ padding: '20px', margin: 'auto', border: '1px solid #ccc' }}>
            <h1 style={{ marginLeft: '30vw', fontSize: "larger" }}>Invoice</h1>
            <p><strong>Invoice Number:</strong> {invoice[0].id ? invoice[0].id : ''}</p>
            <p><strong>Date:</strong> {invoice[0].created_at ? invoice[0].created_at : ''}</p>
            <br />

            <h3 style={{ fontSize: "large" }}>Customer Information</h3>
            <p><strong>Name:</strong> {invoice[0].customer_name ? invoice[0].customer_name : ''}</p>
            <p><strong>Address:</strong> {invoice[0].customer_address ? invoice[0].customer_address : ''}</p>

            <h3 style={{ marginLeft: '29vw', fontSize: "large" }}>Invoice Details</h3>
            <hr></hr>
            <table border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
              <colgroup>
                <col style={{ width: '5%' }} />
                <col style={{ width: '5%' }} />
                <col style={{ width: '5%' }} />
                <col style={{ width: '5%' }} />
                <col style={{ width: '5%' }} />
                <col style={{ width: '5%' }} />
                <col style={{ width: '5%' }} />
                <col style={{ width: '5%' }} />
                <col style={{ width: '5%' }} />
                <col style={{ width: '5%' }} />
              </colgroup>
              <thead>
                <tr>
                  <th>Sr. no.</th>
                  {Object.keys(billTable).map((e) => (
                    <th>{e}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {invoice.map((item, index) => (
                  <tr key={index}>
                    <td style={{ "text-align": "center" }}>{index + 1}</td>
                    {Object.values(billTable).map((e) => (
                      <td style={{ "text-align": "center" }}>{item[e]}</td>

                    ))}
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="9" style={{ textAlign: 'right' }}><strong>Subtotal:</strong> </td>
                  <td>Rs. {invoice[0].total_amount}</td>
                </tr>
                <tr>
                  <td colSpan="9" style={{ textAlign: 'right' }}><strong>Total:</strong></td>
                  <td>Rs. {invoice[0].total_amount}</td>
                </tr>
              </tfoot>
            </table>
          </div>

        </div>
      </div>
    );
  };

  const handleErrorResponse = (status, message) => {
    if (status === 403) {
      showAlert("You are not authorized.");
    } else if (status === 400) {
      showAlert(message || "Invoice with the same name already exists");
    } else {
      showAlert("An error occurred.");
    }
  };

  const showAlert = (msg) => {
    toast.info(msg);
  };

  const TableRow = React.memo(
    ({ count, checkboxes, onCheckboxChange, rowIndex }) => {
      const [isPopupOpen, setPopupOpen] = useState(false);

      const openPopup = () => setPopupOpen(true);
      const closePopup = () => setPopupOpen(false);

      return (<>
        <tr>
          <td className="px-6 py-4 whitespace-nowrap" >
            {count}.
          </td>
          {Object.values(table).map((value, colIndex) => (
            <td style={{ cursor: "pointer" }} className="px-6 py-4 whitespace-nowrap" key={colIndex} onClick={openPopup}>
              {checkboxes[0][value]}
            </td>
          ))}
        </tr>
        <InvoiceComponent invoice={checkboxes} isOpen={isPopupOpen} onClose={closePopup} />
      </>
      );
    }
  );

  return (
    <div className="relative bg-blue-50 animate__animated animate__fadeIn">
      <ToastContainer className="mt-14" />
      <table className="min-w-full divide-y divide-gray-200 mt-4">
        <thead className="bg-gray-200">
          <tr>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
              Sr. no.
            </th>
            {Object.keys(table).map((e) => (
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                {e}
              </th>
            ))}
          </tr>
        </thead>
        {Object.keys(invoice).length > 0 &&
          Object.keys(invoice).map((row, index) => (
            <TableRow
              count={index + 1}
              checkboxes={invoice[row]}
            />
          ))}
      </table>
    </div>
  );
};

export default Invoice;