import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import ReactSelect from 'react-select'; // Import ReactSelect
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify'; // Moved up
import 'react-toastify/dist/ReactToastify.css'; // Moved down
const config = require('../../config')
const Region = () => {
  const [regionName, setRegionName] = useState('');
  const [zoneId, setzoneId] = useState('');
  const [zones, setzones] = useState([]);
  const [countryId, setcountryId] = useState('');
  const [countryName, setCountryName] = useState('');
  const [countries, setcountries] = useState([]);
  const [filteredzones, setFilteredzones] = useState([]);
  const [Regions, setRegions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editItemName, setEditItemName] = useState('');
  const itemsPerPage = 5;
  const [key, setKey] = useState(0);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchzones(); // Fetch initial data when component mounts
    fetchRegions();
  }, []);

  const fetchzones = async () => {
    try {
      const response = await fetch(config.backendDomain + '/zone', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setzones(data);
      setFilteredzones(data);

      // Fetch countries based on zones
      const countryResponse = await fetch(config.backendDomain + '/country', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const countryData = await countryResponse.json();
      setcountries(countryData);
    } catch (error) {
      console.error('Error fetching zones and countries:', error);
    }
  };

  const handlezoneSearch = (searchTerm) => {
    const filtered = zones.filter((zone) =>
      zone.zone_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    setFilteredzones(filtered);
  };

  const fetchRegions = async () => {
    try {
      const response = await fetch(config.backendDomain + '/Region', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      // Sort the data by created_date in descending order
      const sortedData = data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
      setRegions(sortedData);
    } catch (error) {
      console.error('Error fetching Regions:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate that countryName is not empty
    if (!countryName) {
      showAlert('Country name is required');
      return;
    }

    console.log('Request Body:', JSON.stringify({ regionName, zoneId, countryId, countryName }));

    try {
      const response = await fetch(config.backendDomain + '/Region', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ regionName, zoneId, countryId, countryName }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        setRegionName('');
        setzoneId('');
        setcountryId('');
        setCountryName('');
        fetchRegions();
        // setKey((prevKey) => prevKey + 1);
        // Fetch latest data after successful submission
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error creating Region:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleEdit = async (id, newName) => {
    try {
      const response = await fetch(config.backendDomain + `/Region/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ regionName: newName }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchRegions(); // Fetch latest data after successful edit
        handleCloseEditPopup();
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error updating Region:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(config.backendDomain + `/Region/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchRegions(); // Fetch latest data after successful deletion
      } else if (response.status === 400) {
        showAlert(data.message); // Show associated data error message
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error deleting Region:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleErrorResponse = (status, message) => {
    if (status === 403) {
      showAlert('You are not authorized.');
    } else if (status === 400) {
      showAlert(message || 'Region with the same name already exists');
    } else {
      showAlert('An error occurred.');
    }
  };

  const showAlert = (msg) => {
    toast.info(msg);
  };

  const handleOpenEditPopup = (id, newName) => {
    setEditItemId(id);
    setEditItemName(newName);
    setShowEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setEditItemId(null);
    setEditItemName('');
    setShowEditPopup(false);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Regions.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div key={key} className="relative bg-gray-100 animate__animated animate__fadeIn">
      <ToastContainer className="mt-14" />
      <div className="container mx-auto py-8 mt-0">
        <div className="relative bg-blue-50">
          <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1">Region Gateway</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3 relative">
              <label
                className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
              >
                Enter Region Name
              </label>
              <input
                type="text"
                id="regionName"
                pattern="[a-zA-Z0-9 ]+"
                title="Please enter only letters, numbers, and spaces"
                className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter Region Name"
                value={regionName}
                onChange={(e) => setRegionName(e.target.value)}
                required
              />
            </div>
            <div className="mb-3 relative">

              <ReactSelect
                id="zoneId"
                className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                options={filteredzones.map((zone) => ({
                  value: zone.zone_id,
                  label: zone.zone_name,
                }))}
                value={filteredzones.find((zone) => zone.zone_name === zoneId)}
                onChange={(selectedOption) => {
                  const selectedZone = zones.find((zone) => zone.zone_id === selectedOption.value);
                  if (selectedZone) {
                    setzoneId(selectedOption.value);
                    setcountryId(selectedZone.country_id);
                    setCountryName(selectedZone.country_name);
                  }
                }}
                placeholder="Select zone"
              />
              <label
                className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
              >
                Select zone
              </label>
            </div>
            {zoneId && ( // Render country select only if zone is selected
              <div className="mb-3 relative">
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Select Country
                </label>
                <input
                  type="text"
                  id="countryName"
                  className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Select Country"
                  value={countryName}
                  readOnly // Make the input read-only
                />
              </div>
            )}
            <button
              className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Create Region
            </button>
          </form>
          <table className="min-w-full divide-y divide-gray-200 mt-6">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Region ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Region </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Zone </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Country </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Modified Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentItems.map((region) => (
                <tr key={region.region_id}>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{region.region_id}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{region.region_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{region.zone_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{region.country_name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{region.created_date}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">{region.modified_date}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-xs">
                    <button
                      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mr-2"
                      onClick={() => handleOpenEditPopup(region.region_id, region.region_name)}
                    >
                      Edit
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => handleDelete(region.region_id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-start items-center mt-4">
            <div>
              {currentPage > 1 && (
                <button
                  className="mr-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                </button>
              )}
            </div>
            <div className="flex space-x-2">
              {[1, 2, 3, 4].map((page) => (
                <button
                  key={page}
                  className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline ${currentPage === page ? 'bg-orange-400' : ''
                    }`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}
            </div>
            <div>
              {currentPage < Math.ceil(Regions.length / itemsPerPage) && (
                <button
                  className="ml-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <ChevronRightIcon className="h-5 w-5" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Edit Popup */}
      {showEditPopup && (
        <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center ">
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center">
            <div className="relative mt-8 bg-gray-100 w-[800px] p-4 left-24 rounded-lg shadow-lg border border-dark">
              <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1">Edit Region</h2>

              <div className="mb-3 relative">
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Edit Region Name
                </label>
                <input
                  type="text"
                  value={editItemName}
                  onChange={(e) => setEditItemName(e.target.value)}
                  className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Edit Region Name"
                />
              </div>
              <div className="mb-3 relative">

                <ReactSelect
                  id="zoneId"
                  className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  options={filteredzones.map((zone) => ({
                    value: zone.zone_id,
                    label: zone.zone_name,
                  }))}
                  value={filteredzones.find((zone) => zone.zone_name === zoneId)}
                  onChange={(selectedOption) => {
                    const selectedZone = zones.find((zone) => zone.zone_id === selectedOption.value);
                    if (selectedZone) {
                      setzoneId(selectedOption.value);
                      setcountryId(selectedZone.country_id);
                      setCountryName(selectedZone.country_name);
                    }
                  }}
                  placeholder="Select zone"
                />
                <label
                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Select zone
                </label>
              </div>
              <div className="mb-3 relative">

              </div>
              {zoneId && ( // Render country select only if zone is selected
                <div className="mb-3 relative">
                  <label
                    className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                  >
                    Select Country
                  </label>
                  <input
                    type="text"
                    id="countryName"
                    className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Select Country"
                    value={countryName}
                    readOnly // Make the input read-only
                  />
                </div>
              )}

              <div className="mt-4 flex justify-end">
                <button
                  onClick={() => handleEdit(editItemId, editItemName)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                >
                  Save Changes
                </button>
                <button
                  onClick={handleCloseEditPopup}
                  className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Region;
