import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import ReactSelect from 'react-select'; // Import ReactSelect
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify'; // Moved up
import 'react-toastify/dist/ReactToastify.css'; // Moved down

const StationeryPurchase = () => {
  const [billName, setBillName] = useState('');
  const [Bills, setBills] = useState([]);
  const [branches, setBranches] = useState([]);
  const [filteredBranches, setFilteredBranches] = useState([]);
  const [parentBranchId, setParentBranchId] = useState('');
  const [parentBranch, setParentBranch] = useState('');
  const [vendors, setVendors] = useState([]);
  const [filteredVendors, setFilteredVendors] = useState([]);
  const [vendorId, setVendorId] = useState('');
  const [VendorName, setVendorName] = useState('');
  const [selectedProducts, setSelectedProducts] = useState();
  const [filteredProducts, setFilteredProducts] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [Qty, setQty] = useState('');
  const [Rate, setRate] = useState('');
  const [startFrom, setStartFrom] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [showEditPopup, setShowEditPopup] = useState(false);
  // const [editItemId, setEditItemId] = useState(null);
  // const [editItemName, setEditItemName] = useState('');
  const itemsPerPage = 5;
  const [key, setKey] = useState(0);
  const token = localStorage.getItem('token');
  const config = require('../../config')

  //for edit
  const [editSelectedBranch, setEditSelectedBranch] = useState()
  const [editBillName, setEditBillName] = useState()
  const [editSelectedVendor, setEditSelectedVendor] = useState()
  const [editSelectedProducts, setEditSelectedProducts] = useState()
  const [editStartFrom, setEditStartFrom] = useState()
  const [editQty, setEditQty] = useState()
  const [editRate, setEditRate] = useState()
  const [editStartDate, setEditStartDate] = useState()
  const [currId, setCurrId] = useState()
  useEffect(() => {
    fetchBranches();
    fetchVendors();
    fetchProducts();
    fetchBills();
  }, []);


  const fetchBranches = async () => {
    try {
      const response = await fetch(config.backendDomain + '/parentbranch', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setBranches(data);
      setFilteredBranches(data); // Initialize filtered data with all branches
    } catch (error) {
      console.error('Error fetching branches:', error);
    }
  };

  const handleBranchSearch = (searchTerm) => {
    const filtered = branches.filter((branch) =>
      branch.branch_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    console.log('Filtered Branches:', filtered); // Add this line for debugging
    setFilteredBranches(filtered);
  };

  const fetchVendors = async () => {
    try {
      const response = await fetch(config.backendDomain + '/vendorsearch', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setVendors(data);
      setFilteredVendors(data); // Initialize filtered data with all branches
    } catch (error) {
      console.error('Error fetching Vendors:', error);
    }
  };

  const handleVendorSearch = (searchTerm) => {
    const filtered = vendors.filter((vendors) =>
      vendors.vendors_name.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    console.log('Filtered Vendors:', filtered); // Add this line for debugging
    setFilteredVendors(filtered);
  };

  const fetchProducts = async () => {
    try {
      const response = await fetch(config.backendDomain + '/productsearch', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setFilteredProducts(data); // Initialize filtered data with all branches
    } catch (error) {
      console.error('Error fetching Vendors:', error);
    }
  };

  // const handleProductSearch = (searchTerm) => {
  //   const filtered = products.filter((products) =>
  //     products.products_name.toLowerCase().startsWith(searchTerm.toLowerCase())
  //   );
  //   console.log('Filtered Products:', filtered); // Add this line for debugging
  //   setFilteredProducts(filtered);
  // };

  const fetchBills = async () => {
    try {
      const response = await fetch(config.backendDomain + '/stationerypurchase', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      // Sort the data by created_date in descending order
      const sortedData = data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
      setBills(sortedData);
    } catch (error) {
      console.error('Error fetching Stationery:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Calculate EndStock by adding StartFrom and Qty
    const endStockValue = Number(startFrom) + Number(Qty);
    const netAmount = Number(Rate) * Number(Qty);

    try {
      const response = await fetch(config.backendDomain + '/Stationerypurchase', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ billName, parentBranchId, parentBranch, vendorId, VendorName, productId: selectedProducts.value, ProductName: selectedProducts.label, Qty, Rate, startFrom, EndStock: endStockValue, startDate, Amount: netAmount }),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        setBillName('');
        setParentBranchId('');
        setParentBranch('');
        setVendorId('');
        setVendorName('');
        setQty('');
        setRate('');
        setStartFrom('');
        setStartDate('');
        fetchBills();

        // setKey((prevKey) => prevKey + 2);

        // Fetch latest data after successful submission
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error in purchase:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleEdit = async (Obj) => {
    try {
      const response = await fetch(config.backendDomain + `/stationerypurchase/${Obj.id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(Obj),
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchBills(); // Fetch latest data after successful edit
        handleCloseEditPopup();
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error updating Customer:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleDelete = async (id, available_stock) => {
    try {
      // if(parseInt(available_stock)>0){
      //   showAlert('Empty the Stock First')
      //   return 
      // }
      const response = await fetch(config.backendDomain + `/stationerypurchase/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        fetchBills(); // Fetch latest data after successful deletion
      } else {
        handleErrorResponse(response.status, data.message);
      }
    } catch (error) {
      console.error('Error deleting Customer:', error);
      handleErrorResponse(500, 'An error occurred.'); // Internal server error
    }
  };

  const handleErrorResponse = (status, message) => {
    if (status === 403) {
      showAlert('You are not authorized.');
    } else if (status === 400) {
      showAlert(message || 'Purchase with the same entry already exists');
    } else {
      showAlert('An error occurred.');
    }
  };

  const showAlert = (msg) => {
    toast.info(msg);
  };

  const handleOpenEditPopup = (id) => {
    // setEditItemId(id);
    setCurrId(id.bill_id)
    setEditSelectedBranch({ label: id.parent_branch, value: id.parentbranch_id })
    setEditBillName(id.bill_name)
    setEditSelectedVendor({ label: id.vendor_name, value: id.vendor_id })
    setEditSelectedProducts({
      value: id.product_id,
      label: id.product_name,
    })
    setEditStartFrom(id.start_from);
    // setEditEndTo(id.end_to)
    setEditQty(id.qty)
    setEditRate(id.rate)
    setEditStartDate(id.purchase_date)
    setShowEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setShowEditPopup(false);
    // setEditItemId(null);
    setEditSelectedBranch(null)
    setEditBillName(null)
    setEditSelectedVendor(null)
    setEditSelectedProducts(null)
    setEditStartFrom(null);
    setEditQty(null)
    setEditRate(null)
    setEditStartDate(null)
    setCurrId(null)

  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Bills.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div key={key} className="relative bg-gray-100 animate__animated animate__fadeIn">
      <ToastContainer className="mt-14" />
      <div className="relative bg-blue-50">
        <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1 ">Stock of Stationery Purchase</h2>

        <form onSubmit={handleSubmit} className="grid grid-cols-4 gap-4">
          <div className="mb-3 relative">
            <label

              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
            >
              Vendor Bill Number
            </label>
            <input
              type="text"
              id="bill"
              pattern="[a-zA-Z0-9/-]{1,32}"
              title="Please enter a valid bill number without special character"
              className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Vendor Bill Number"
              value={billName}
              onChange={(e) => setBillName(e.target.value)}
              required
            />
          </div>


          <div className="mb-3 relative">

            <ReactSelect
              id="parentBranchId"
              className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              options={filteredBranches.map((branch) => ({
                value: branch.branch_id,
                label: branch.branch_name,
              }))}
              value={filteredBranches.find((branch) => branch.branch_name === parentBranchId)}
              onChange={(selectedOption) => {
                const selectedBranch = branches.find((branch) => branch.branch_id === selectedOption.value);
                if (selectedBranch) {
                  setParentBranchId(selectedOption.value);
                  setParentBranch(selectedOption.label);
                }
              }}
              placeholder="Select Branch"
            />
            <label

              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
            >
              Select Branch
            </label>
          </div>

          <div className="mb-3 relative">

            <ReactSelect
              id="vendorId"
              className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              options={filteredVendors.map((vendor) => ({
                value: vendor.vendor_id,
                label: vendor.vendor_name,
              }))}
              value={filteredVendors.find((vendor) => vendor.vendor_name === vendorId)}
              onChange={(selectedOption) => {
                const selectedVendor = vendors.find((vendor) => vendor.vendor_id === selectedOption.value);
                if (selectedVendor) {
                  setVendorId(selectedOption.value);
                  setVendorName(selectedOption.label);
                }
              }}
              placeholder="Select Vendor"
            />
            <label

              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
            >
              Select Vendor
            </label>
          </div>

          <div className="mb-3 relative">

            <ReactSelect
              id="productId"
              className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              options={filteredProducts.map((product) => ({
                value: product.product_id,
                label: product.product_name,
                startFrom: product.number_input
              }))}
              value={selectedProducts}
              onChange={(e) => { setSelectedProducts(e); setStartFrom(e.startFrom) }}
              placeholder="Select Product"
            />
            <label

              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
            >
              Select Product
            </label>
          </div>
          <div className="mb-3 relative">
            <label

              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
            >
              Enter Quantity
            </label>
            <input
              type="text"
              id="Qty"
              pattern="[0-9]{1,8}"
              title="Please enter a number with 1 to 8 digits"
              className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter Quantity"
              value={Qty}
              onChange={(e) => setQty(e.target.value)}
              required
            />
          </div>
          <div className="mb-3 relative">
            <label

              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
            >
              Rate per WayBill
            </label>
            <input
              type="text"
              id="Rate"
              pattern="^(?:\d*\.\d{1,2}|\d+)$"
              title="Please enter a valid amount (e.g., 100, 100.00, 100.5)"
              className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Rate"
              value={Rate}
              onChange={(e) => setRate(e.target.value)}
              required
            />
          </div>
          <div className="mb-3 relative">
            <label

              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
            >
              Stock Starting Number
            </label>
            <input
              type="number"
              id="StartFrom"
              pattern="[0-9]{1,20}"
              title="Please enter a number with 1 to 20 digits"
              className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Stock Starting Number"
              value={startFrom}
              onChange={(e) => {
                const fixedPart = selectedProducts?.startFrom || "";
                const inputValue = e.target.value;
                if (!inputValue.startsWith(fixedPart)) {
                  return;
                }
                setStartFrom(inputValue);
              }}
              required
            />
          </div>

          <div className="mb-3 relative">
            <label

              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
            >
              Stock End Number
            </label>
            <input
              type="text"
              id="EndStock"
              className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Stock End Number"
              value={Number(Qty) > 0 && Number(startFrom) ? Number(startFrom) + Number(Qty) - 1 : null}
              readOnly // Make the input read-only
              required
            />
          </div>

          <div className="mb-3 relative">

            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              maxDate={new Date()}
              placeholderText="Select Purchase Date"
              className=" shadow-lg w-[300px] py-2 px-3 pl-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
            <label

              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
            >
              Select Purchase Date
            </label>
          </div>

          <div className="mb-3 relative">
            <label

              className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
            >
              Invoice Amount
            </label>
            <input
              type="text"
              id="Amount"
              className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Invoice Amount"
              value={Number(Rate) * Number(Qty) ? Number(Rate) * Number(Qty) : null}
              readOnly // Make the input read-only
              required
            />

          </div>

          <div className="flex-center">
            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">Submit</button>
          </div>
        </form>
        <table className="min-w-full divide-y divide-gray-200 mt-6">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Invoice No.</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Branch</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Qty</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Start From</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">To end </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Purchase Date </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Available Stock </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentItems.map((stationerypurchase) => (
              <tr key={stationerypurchase.stationerypurchase_id}>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerypurchase.bill_name}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerypurchase.parent_branch}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerypurchase.product_name}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerypurchase.qty}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerypurchase.start_from}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerypurchase.end_to}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerypurchase.amount}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerypurchase.purchase_date}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerypurchase.available_stock}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">{stationerypurchase.created_date}</td>
                <td className="px-4 py-4 whitespace-nowrap text-xs">
                  <button
                    className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mr-2"
                    onClick={() => handleOpenEditPopup(stationerypurchase)}
                  >
                    Edit
                  </button>
                  <button
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                    onClick={() => handleDelete(stationerypurchase.bill_id, stationerypurchase.available_stock)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-start items-center mt-4">
          <div>
            {currentPage > 1 && (
              <button
                className="mr-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <ChevronLeftIcon className="h-5 w-5" />
              </button>
            )}
          </div>
          <div className="flex space-x-2">
            {[1, 2, 3, 4].map((page) => (
              <button
                key={page}
                className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline ${currentPage === page ? 'bg-orange-400' : ''
                  }`}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            ))}
          </div>
          <div>
            {currentPage < Math.ceil(Bills.length / itemsPerPage) && (
              <button
                className="ml-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <ChevronRightIcon className="h-5 w-5" />
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Edit Popup */}
      {showEditPopup && (
        <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center ">
          <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center">
            <div className="relative mt-12 bg-gray-100 w-[800px] p-4 left-24 rounded-lg shadow-lg border border-dark">
              <h2 className="shadow-lg text-xl h-10 font-bold bg-blue-500 text-white mb-6 pl-3 pt-1">Edit Stationery Purchase</h2>
              <div className="mb-3 relative">
                <label

                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Edit Vendor Bill Number
                </label>
                <input
                  type="text"
                  id="bill"
                  pattern="[a-zA-Z0-9/-]{1,32}"
                  title="Please enter a valid bill number without special character"
                  className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Edit Vendor Bill Number"
                  value={editBillName}
                  onChange={(e) => setEditBillName(e.target.value)}
                  required
                />
              </div>


              <div className="mb-3 relative">

                <ReactSelect

                  id="parentBranchId"
                  className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  options={branches.map((branch) => ({
                    value: branch.branch_id,
                    label: branch.branch_name,
                  }))}
                  value={editSelectedBranch}
                  onChange={setEditSelectedBranch}
                  placeholder="Select Branch"
                />
                <label

                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Select Branch
                </label>
              </div>

              <div className="mb-3 relative">

                <ReactSelect
                  id="vendorId"
                  className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  options={vendors.map((vendor) => ({
                    value: vendor.vendor_id,
                    label: vendor.vendor_name,
                  }))}
                  value={editSelectedVendor}
                  onChange={setEditSelectedVendor}
                  placeholder="Select Vendor"
                />
                <label

                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Select Vendor
                </label>
              </div>

              <div className="mb-3 relative">

                <ReactSelect
                  id="productId"
                  className=" shadow-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  options={filteredProducts.map((product) => ({
                    value: product.product_id,
                    label: product.product_name,
                    startFrom: product.number_input
                  }))}
                  value={editSelectedProducts}
                  onChange={(e) => { setEditSelectedProducts(e); setEditStartFrom(e.startFrom) }}
                  placeholder="Select Product"
                />
                <label

                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Select Product
                </label>
              </div>
              <div className="mb-3 relative">
                <label

                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Enter Quantity
                </label>
                <input
                  type="number"
                  id="Qty"
                  pattern="[0-9]{1,8}"
                  title="Please enter a number with 1 to 8 digits"
                  className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter Quantity"
                  value={editQty}
                  onChange={(e) => setEditQty(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3 relative">
                <label

                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Rate per WayBill
                </label>
                <input
                  type="text"
                  id="Rate"
                  pattern="^(?:\d*\.\d{1,2}|\d+)$"
                  title="Please enter a valid amount (e.g., 100, 100.00, 100.5)"
                  className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Rate"
                  value={editRate}
                  onChange={(e) => setEditRate(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3 relative">
                <label

                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Stock Starting Number
                </label>
                <input
                  type="text"
                  id="StartFrom"
                  pattern="[0-9]{1,20}"
                  title="Please enter a number with 1 to 8 digits"
                  className=" shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Stock Starting Number"
                  value={editStartFrom}
                  onChange={(e) => setEditStartFrom(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3 relative">
                <label

                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Stock End Number
                </label>
                <input
                  type="text"
                  id="EndStock"
                  className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Stock End Number"
                  value={Number(editQty) > 0 && Number(editStartFrom) ? Number(editStartFrom) + Number(editQty) - 1 : null}
                  readOnly // Make the input read-only
                  required
                />
              </div>

              <div className="mb-3 relative">

                <DatePicker
                  selected={editStartDate}
                  onChange={(date) => setEditStartDate(date)}
                  dateFormat="MM/dd/yyyy"
                  maxDate={new Date()}
                  placeholderText="Select Purchase Date"
                  className=" shadow-lg w-[765px] py-2 px-3 pl-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
                <label

                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Select Purchase Date
                </label>
              </div>

              <div className="mb-3 relative">
                <label

                  className="absolute -top-2 right-0 bg-gray-100 px-2 text-[0.625rem] text-gray-500 rounded-sm"
                >
                  Invoice Amount
                </label>

                <input
                  type="text"
                  id="Amount"
                  className="shadow-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Invoice Amount"
                  value={Number(editRate) * Number(editQty)}
                  readOnly // Make the input read-only
                  required
                />
              </div>
              <div className="mt-4 flex justify-end">
                <button
                  onClick={() => handleEdit({
                    parentBranchId: editSelectedBranch.value,
                    parentBranch: editSelectedBranch.label
                    , billName: editBillName
                    , vendorId: editSelectedVendor.value,
                    VendorName: editSelectedVendor.label
                    , productId: editSelectedProducts.value
                    , ProductName: editSelectedProducts.label
                    , startFrom: editStartFrom
                    , Qty: editQty
                    , Rate: editRate,
                    EndStock: Number(editStartFrom) + Number(editQty) - 1,
                    Amount: Number(editRate) * Number(editQty)
                    , startDate: editStartDate, id: currId
                  })}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                >
                  Save Changes
                </button>
                <button
                  onClick={handleCloseEditPopup}
                  className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StationeryPurchase;
