import React, { useState, useEffect } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chart from "chart.js/auto";
const config = require("../../config");

const BookingReport = () => {
  const [data, setData] = useState([]);
  const [summarizedData, setSummarizedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [key, setKey] = useState(0);
  const token = localStorage.getItem("token");

  const itemsPerPage = 8;

  useEffect(() => {
    fetchData(); // Fetch initial data when the component mounts
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(config.backendDomain + "/bookingsearch", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let result = await response.json();

      result = result.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
      setData(result);
      calculateSummary(result);
      renderCharts(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const calculateSummary = (data) => {
    const summary = data.reduce((acc, item) => {
      const region = item.originregion_name || "Unknown Region";
      if (!acc[region]) {
        acc[region] = {
          salesAmount: 0,
          packets: 0,
          weight: 0,
          region: region,
        };
      }
      acc[region].salesAmount += parseFloat(item.booking_amount || 0);
      acc[region].packets += parseInt(item.packets || 0, 10);
      acc[region].weight += parseFloat(item.volumetric_weight || 0);
      return acc;
    }, {});
    setSummarizedData(Object.values(summary));
  };
  

  const renderCharts = (data) => {
    const salesTrend = data.reduce((acc, item) => {
      const month = new Date(item.booking_date).toLocaleString("default", { month: "short" });
      acc[month] = (acc[month] || 0) + 1;
      return acc;
    }, {});

    const serviceTrend = data.reduce((acc, item) => {
      acc[item.paymentmode_name] = (acc[item.paymentmode_name] || 0) + item.packets;
      return acc;
    }, {});

    const lineChartCtx = document.getElementById("lineChart").getContext("2d");
    new Chart(lineChartCtx, {
      type: "line",
      data: {
        labels: Object.keys(salesTrend),
        datasets: [
          {
            label: "Waybill Count",
            data: Object.values(salesTrend),
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
          },
        ],
      },
    });

    const barChartCtx = document.getElementById("barChart").getContext("2d");
    new Chart(barChartCtx, {
      type: "bar",
      data: {
        labels: Object.keys(serviceTrend),
        datasets: [
          {
            label: "Total Packets",
            data: Object.values(serviceTrend),
            backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 205, 86)"],
          },
        ],
      },
    });
  };

  const calculateStats = () => {
    const today = new Date().toISOString().split("T")[0]; // Today's date in 'YYYY-MM-DD' format
    let totalSales = data.length;
    let totalBooking = 0;
    let todaySales = 0;
    let todayBooking = 0;

    data.forEach((item) => {
      totalBooking += item.actual_weight;
      if (item.booking_date === today) {
        todaySales += item.packets;
        todayBooking += item.volumetric_weight;
      }
    });

    return { totalSales, totalBooking, todaySales, todayBooking };
  };

  const stats = calculateStats();
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div key={key} className="bg-gray-100 min-h-screen p-4">
      <ToastContainer />

      {/* Sales Analytics Section */}
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 mb-6">
        <div className="bg-blue-500 text-white p-4 rounded shadow">
          <h3 className="text-lg font-semibold">Total Sales</h3>
          <p className="text-2xl">{stats.totalSales}</p>
        </div>
        <div className="bg-green-500 text-white p-4 rounded shadow">
          <h3 className="text-lg font-semibold">Today Sales</h3>
          <p className="text-2xl">{stats.todaySales}</p>
        </div>
        <div className="bg-purple-500 text-white p-4 rounded shadow">
          <h3 className="text-lg font-semibold">Total Booking</h3>
          <p className="text-2xl">{stats.totalBooking.toFixed(2)}</p>
        </div>
        <div className="bg-orange-500 text-white p-4 rounded shadow">
          <h3 className="text-lg font-semibold">Today's Booking</h3>
          <p className="text-2xl">{stats.todayBooking.toFixed(2)}</p>
        </div>
      </div>

      {/* Trend Charts */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
        <div className="bg-white p-6 rounded shadow">
          <h3 className="text-lg font-semibold mb-4">Sales Trend</h3>
          <canvas id="lineChart"></canvas>
        </div>
        <div className="bg-white p-6 rounded shadow">
          <h3 className="text-lg font-semibold mb-4">Service Trend</h3>
          <canvas id="barChart"></canvas>
        </div>
      </div>

      {/* Recent Bookings Table */}
      <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
        <h3 className="text-lg font-semibold mb-4 text-gray-700">Recent Bookings</h3>
        <table className="min-w-full table-auto border-collapse border border-gray-300">
          <thead>
          <tr className="bg-gray-200 text-gray-700">
              <th className="border border-gray-300 px-4 py-2 text-center">WayBill</th>
              <th className="border border-gray-300 px-4 py-2 text-center">Branch</th>
              <th className="border border-gray-300 px-4 py-2 text-center">Payment Mode</th>
              <th className="border border-gray-300 px-4 py-2 text-center">Dispatch Mode</th>
              <th className="border border-gray-300 px-4 py-2 text-center">Pcs</th>
              <th className="border border-gray-300 px-4 py-2 text-center">Act. Wt</th>
              <th className="border border-gray-300 px-4 py-2 text-center">Vol. Wt</th>
              <th className="border border-gray-300 px-4 py-2 text-center">Booking Date</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item) => (
              <tr key={item.waybill_id} className="hover:bg-gray-100 transition-colors duration-200">
                <td className="border border-gray-300 px-4 py-2 text-center">{item.waybill_number}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">{item.parent_branch}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">{item.paymentmode_name}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">{item.transportmode_name}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">{item.packets}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">{item.actual_weight}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">{item.volumetric_weight}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">{item.booking_date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

        {/* Sales Analytical Table */}
      <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 mt-6">
        <h3 className="text-lg font-semibold mb-4 text-gray-700">Sales Analysis</h3>
        <table className="min-w-full table-auto border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200 text-gray-700">
              <th className="border border-gray-300 px-4 py-2 text-center">Region</th>
              <th className="border border-gray-300 px-4 py-2 text-center">Sales Amount</th>
              <th className="border border-gray-300 px-4 py-2 text-center">Packets</th>
              <th className="border border-gray-300 px-4 py-2 text-center">Act Weight</th>
              <th className="border border-gray-300 px-4 py-2 text-center">Cha Weight</th>
            </tr>
          </thead>
          <tbody>
            {summarizedData.map((item, index) => (
              <tr key={index} className="hover:bg-gray-100 transition-colors duration-200">
                <td className="border border-gray-300 px-4 py-2 text-center">{item.region}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">{item.salesAmount.toFixed(2)}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">{item.packets}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">{item.weight.toFixed(2)}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">{item.weight.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BookingReport;
